/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import {
  OfferingProfile_Common_OfferingAttributes_PartFragmentDoc,
  Atm_Attributes_FieldsFragmentDoc,
  Atm_Selldown_FieldsFragmentDoc,
  Atm_Selldown_Term_FieldsFragmentDoc,
  AtmOfferings_Selldown_FieldsFragmentDoc,
  OfferingProfile_Shareholder_PartFragmentDoc,
  OfferingProfile_Common_CornerstoneInvestmentFragmentDoc,
} from '../../../../../common/graphql/__generated__/commonFragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GlobalEcmOfferings_Terms_PartFragment = {
  __typename?: 'Term';
  aggregatePrincipalAmount?: number | null;
  aggregatePrincipalInclOverallotmentExercised?: number | null;
  principalAmountOverallotmentAuthorized?: number | null;
  principalAmountPerNote?: number | null;
  couponTalkPercentageLow?: number | null;
  couponTalkPercentageHigh?: number | null;
  premiumTalkLowPercentage?: number | null;
  premiumTalkHighPercentage?: number | null;
  conversionPrice?: number | null;
  conversionRatio?: number | null;
  conversionReferencePrice?: number | null;
  principalAmountOverallotmentExercised?: number | null;
  effectiveConversionPremium?: number | null;
  effectiveConversionPrice?: number | null;
  versusTalkDisplayName?: string | null;
  premiumPercentage?: number | null;
  couponPercentage?: number | null;
  pctOfferPrice?: number | null;
  hasZeroCoupon?: boolean | null;
};

export type GlobalEcmOfferings_ListQueryVariables = Types.Exact<{
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  order?: Types.InputMaybe<Array<Types.OfferingSortInput> | Types.OfferingSortInput>;
  where?: Types.InputMaybe<Types.OfferingFilterInput>;
}>;

export type GlobalEcmOfferings_ListQuery = {
  __typename?: 'Query';
  offerings?: {
    __typename?: 'OfferingCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'Offering';
      id: string;
      publicFilingDate?: string | null;
      launchDate?: string | null;
      marketTimingDisplayName?: string | null;
      pricingDate?: string | null;
      firstTradeDate?: string | null;
      settlementDate?: string | null;
      exchangeRegionDisplayName?: string | null;
      exchangeCountryDisplayName?: string | null;
      exchangeMicDisplayName?: string | null;
      pricingCurrencyDisplayName?: string | null;
      hasForwardAgreement?: boolean | null;
      maturityDate?: string | null;
      type: Types.OfferingType;
      typeDisplayName?: string | null;
      securityType?: Types.SecurityType | null;
      securityTypeDisplayName?: string | null;
      isRule144A?: boolean | null;
      price?: number | null;
      grossSpreadTotal?: number | null;
      useOfProceeds?: Array<Types.UseOfProceeds> | null;
      useOfProceedsDisplayNames?: Array<string | null> | null;
      symbol?: string | null;
      pricingCurrency?: Types.Currency | null;
      initialTermId?: string | null;
      latestRevisedTermId?: string | null;
      finalTermId?: string | null;
      issuer?: {
        __typename?: 'Issuer';
        cik?: string | null;
        name?: string | null;
        sector?: Types.Sector | null;
        sectorDisplayName?: string | null;
        subSectorDisplayName?: string | null;
        primarySymbol?: string | null;
        primaryCusip?: string | null;
        primaryIsin?: string | null;
        entityTypes?: Array<Types.EntityType> | null;
        headquarters?: string | null;
        naics?: number | null;
      } | null;
      managers: Array<{
        __typename?: 'OfferingManager';
        pctUnderwritingBaseShares?: number | null;
        underwritingBaseShares?: number | null;
        name?: string | null;
        role?: Types.OfferingManagerRole | null;
        roleDisplayName?: string | null;
        sequence?: number | null;
      }>;
      advisers?: Array<{
        __typename?: 'OfferingAdviser';
        role?: Types.AdviserRole | null;
        roleDisplayName?: string | null;
        name?: string | null;
      }> | null;
      attributes?: {
        __typename?: 'OfferingAttributes';
        priceUsd?: number | null;
        netPrice?: number | null;
        splitAdjustedOfferPrice?: number | null;
        latestGrossProceedsBaseUsd?: number | null;
        latestGrossProceedsTotalUsd?: number | null;
        pctGrossSpread?: number | null;
        overAllotmentExercised?: number | null;
        grossSpreadTotalUsd?: number | null;
        confidentialFilingDate?: string | null;
        latestSizeInSecuritiesBase?: number | null;
        initialSizeInSecuritiesBase?: number | null;
        latestSizeInSecuritiesBasePrimary?: number | null;
        latestSizeInSecuritiesBaseSecondary?: number | null;
        latestPctSecondaryShares?: number | null;
        latestOverAllotmentAuthorized?: number | null;
        latestGrossProceedsBase?: number | null;
        latestGrossProceedsTotal?: number | null;
        initialGrossProceedsBase?: number | null;
        initialGrossProceedsBaseUsd?: number | null;
        revisedGrossProceedsBase?: number | null;
        revisedGrossProceedsBaseUsd?: number | null;
        marketCapAtPricing?: number | null;
        marketCapAtPricingUsd?: number | null;
        marketCapPreOfferingUsd?: number | null;
        latestPostOfferingShares?: number | null;
        preOfferingShares?: number | null;
        pctMarketCapAtPricing?: number | null;
        pctMarketCapPreOffering?: number | null;
        preOfferingAdtv?: number | null;
        sizeAsMultipleOfAdtv?: number | null;
        pctFileToOffer?: number | null;
        pctToLastTrade?: number | null;
        pctToVwap?: number | null;
        pctTo52WeekHigh?: number | null;
        grossSpreadTotal?: number | null;
        allInCost?: number | null;
        estimatedFeeUsd?: number | null;
        initialIndicativeGrossProceedsUsd?: number | null;
        initialIpoRangeLow?: number | null;
        initialIpoRangeLowUsd?: number | null;
        initialIpoRangeHigh?: number | null;
        initialIpoRangeHighUsd?: number | null;
        priceVsRangeDisplayName?: string | null;
        pctVsMidpoint?: number | null;
        pctChangeInSize?: number | null;
        revisedIpoRangeLowUsd?: number | null;
        revisedIpoRangeHighUsd?: number | null;
        lastTradeBeforeFiling?: number | null;
        lastTradeBeforeFilingUsd?: number | null;
        lastTradeBeforeOffer?: number | null;
        lastTradeBeforeOfferUsd?: number | null;
        fiftyTwoWeekHigh?: number | null;
        reOfferLow?: number | null;
        reOfferLowUsd?: number | null;
        reOfferHigh?: number | null;
        reOfferHighUsd?: number | null;
        pctReOfferLow?: number | null;
        pctReOfferHigh?: number | null;
        firstDayVolume?: number | null;
        firstDayTurnover?: number | null;
        pctOfferToOpen?: number | null;
        offerToVwap1Day?: number | null;
        pctOfferTo1Day?: number | null;
        pctOfferTo3Day?: number | null;
        pctOfferTo7Day?: number | null;
        pctOfferTo14Day?: number | null;
        pctOfferTo30Day?: number | null;
        pctOfferTo90Day?: number | null;
        pctOfferTo180Day?: number | null;
        pctOfferToOneYear?: number | null;
        pctOfferToPriorQuarter?: number | null;
        pctOfferToCurrent?: number | null;
        totalManagers?: number | null;
        totalBookrunners?: number | null;
        totalNonBookrunners?: number | null;
        totalPctToBookrunners?: number | null;
        totalPctToNonBookrunners?: number | null;
        totalPctToLeftLead?: number | null;
        lockUpPeriod?: number | null;
        lockUpExpirationDate?: string | null;
        lockUpEarlyReleaseDate?: string | null;
        isConditionalLockUp?: boolean | null;
        hasMultipleLockUps?: boolean | null;
        leftLeadName?: string | null;
        leftLeadId?: string | null;
        primaryShareholderName?: string | null;
        primaryShareholderFirmId?: string | null;
        totalPreOfferingOwnership?: number | null;
        totalPctPreOfferingOwnership?: number | null;
        totalPostOfferingOwnership?: number | null;
        totalPctPostOfferingOwnership?: number | null;
        isFirstFollowOn?: boolean | null;
        isCarveOut?: boolean | null;
        isCleanUpTrade?: boolean | null;
        isConcurrentOffering?: boolean | null;
        isDualListed?: boolean | null;
        isEgc?: boolean | null;
        isSyntheticSecondary?: boolean | null;
        isUpListing?: boolean | null;
        useOfProceedsNote?: string | null;
        isCompanyRepurchaseAdditional?: boolean | null;
        isCompanyRepurchaseIncluded?: boolean | null;
        hasCornerstoneInvestors?: boolean | null;
        cornerstoneInvestorsTotalCount?: number | null;
        cornerstoneTotalAmount?: number | null;
        pricingCurrency?: Types.Currency | null;
        cornerstoneOfferingParticipantsAmount?: number | null;
        cornerstonePrivatePlacementAmount?: number | null;
        cornerstoneInvestorsOfferingParticipantsCount?: number | null;
        cornerstoneInvestorsPrivatePlacementCount?: number | null;
      } | null;
      initialTerm?: {
        __typename?: 'Term';
        aggregatePrincipalAmount?: number | null;
        aggregatePrincipalInclOverallotmentExercised?: number | null;
        principalAmountOverallotmentAuthorized?: number | null;
        principalAmountPerNote?: number | null;
        couponTalkPercentageLow?: number | null;
        couponTalkPercentageHigh?: number | null;
        premiumTalkLowPercentage?: number | null;
        premiumTalkHighPercentage?: number | null;
        conversionPrice?: number | null;
        conversionRatio?: number | null;
        conversionReferencePrice?: number | null;
        principalAmountOverallotmentExercised?: number | null;
        effectiveConversionPremium?: number | null;
        effectiveConversionPrice?: number | null;
        versusTalkDisplayName?: string | null;
        premiumPercentage?: number | null;
        couponPercentage?: number | null;
        pctOfferPrice?: number | null;
        hasZeroCoupon?: boolean | null;
      } | null;
      latestRevisedTerm?: {
        __typename?: 'Term';
        aggregatePrincipalAmount?: number | null;
        aggregatePrincipalInclOverallotmentExercised?: number | null;
        principalAmountOverallotmentAuthorized?: number | null;
        principalAmountPerNote?: number | null;
        couponTalkPercentageLow?: number | null;
        couponTalkPercentageHigh?: number | null;
        premiumTalkLowPercentage?: number | null;
        premiumTalkHighPercentage?: number | null;
        conversionPrice?: number | null;
        conversionRatio?: number | null;
        conversionReferencePrice?: number | null;
        principalAmountOverallotmentExercised?: number | null;
        effectiveConversionPremium?: number | null;
        effectiveConversionPrice?: number | null;
        versusTalkDisplayName?: string | null;
        premiumPercentage?: number | null;
        couponPercentage?: number | null;
        pctOfferPrice?: number | null;
        hasZeroCoupon?: boolean | null;
      } | null;
      finalTerm?: {
        __typename?: 'Term';
        aggregatePrincipalAmount?: number | null;
        aggregatePrincipalInclOverallotmentExercised?: number | null;
        principalAmountOverallotmentAuthorized?: number | null;
        principalAmountPerNote?: number | null;
        couponTalkPercentageLow?: number | null;
        couponTalkPercentageHigh?: number | null;
        premiumTalkLowPercentage?: number | null;
        premiumTalkHighPercentage?: number | null;
        conversionPrice?: number | null;
        conversionRatio?: number | null;
        conversionReferencePrice?: number | null;
        principalAmountOverallotmentExercised?: number | null;
        effectiveConversionPremium?: number | null;
        effectiveConversionPrice?: number | null;
        versusTalkDisplayName?: string | null;
        premiumPercentage?: number | null;
        couponPercentage?: number | null;
        pctOfferPrice?: number | null;
        hasZeroCoupon?: boolean | null;
      } | null;
      terms: Array<{
        __typename?: 'Term';
        id?: string | null;
        aggregatePrincipalAmount?: number | null;
        aggregatePrincipalInclOverallotmentExercised?: number | null;
        principalAmountOverallotmentAuthorized?: number | null;
        principalAmountPerNote?: number | null;
        couponTalkPercentageLow?: number | null;
        couponTalkPercentageHigh?: number | null;
        premiumTalkLowPercentage?: number | null;
        premiumTalkHighPercentage?: number | null;
        conversionPrice?: number | null;
        conversionRatio?: number | null;
        conversionReferencePrice?: number | null;
        principalAmountOverallotmentExercised?: number | null;
        effectiveConversionPremium?: number | null;
        effectiveConversionPrice?: number | null;
        versusTalkDisplayName?: string | null;
        premiumPercentage?: number | null;
        couponPercentage?: number | null;
        pctOfferPrice?: number | null;
        hasZeroCoupon?: boolean | null;
      }>;
      cornerstoneInvestments?: Array<{
        __typename?: 'CornerstoneInvestment';
        individualAmount?: number | null;
        totalInvestors: number;
        type: Types.CornerstoneInvestmentType;
        typeDisplayName?: string | null;
        investors: Array<{
          __typename?: 'CornerstoneInvestor';
          id: string;
          cornerstoneInvestmentId: string;
          isExistingCornerstoneInvestor: boolean;
          shareholder: {
            __typename?: 'Shareholder';
            id: string;
            name?: string | null;
            shareholderTypeDisplayName?: string | null;
          };
          cornerstoneInvestment: {
            __typename?: 'CornerstoneInvestment';
            individualAmount?: number | null;
            totalInvestors: number;
            type: Types.CornerstoneInvestmentType;
            typeDisplayName?: string | null;
          };
        }>;
      }> | null;
      shareholders?: Array<{
        __typename?: 'OfferingShareholder';
        shareholderId?: string | null;
        name?: string | null;
        shareholderTypeDisplayName?: string | null;
        preOfferingOwnership?: number | null;
        pctPreOfferingOwnership?: number | null;
        postOfferingOwnership?: number | null;
        pctPostOfferingOwnership?: number | null;
        postOfferingOwnershipIncludingShoe?: number | null;
        pctPostOfferingOwnershipIncludingShoe?: number | null;
        isCarveOutParent?: boolean | null;
      }> | null;
    }> | null;
    pageInfo: {
      __typename?: 'CollectionSegmentInfo';
      hasNextPage: boolean;
      offset: number;
      pageSize: number;
    };
    summaries?: {
      __typename?: 'OfferingListSummary';
      aggregations?: {
        __typename?: 'OfferingAggregations';
        callPrice?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        conversionPrice?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        conversionRatio?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        conversionReferencePrice?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferPrice?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        priceUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        couponPercentage?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        premiumPercentage?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        aggregatePrincipalAmount?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        aggregatePrincipalInclOverallotmentExercised?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        principalAmountPerNote?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        tenor?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctGrossSpread?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferToOpen?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo1Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo3Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo7Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo14Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo30Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo90Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferTo180Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferToCurrent?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        netPrice?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        latestSizeInSecuritiesBase?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        latestPctSecondaryShares?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        latestGrossProceedsBaseUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        latestGrossProceedsTotalUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        marketCapAtPricingUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        marketCapPreOfferingUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        latestPostOfferingShares?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        preOfferingShares?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctMarketCapAtPricing?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctMarketCapPreOffering?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        sizeAsMultipleOfAdtv?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctFileToOffer?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctToLastTrade?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctToVwap?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctTo52WeekHigh?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        grossSpreadTotalUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        allInCost?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        estimatedFeeUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        initialIndicativeGrossProceedsUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctVsMidpoint?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctChangeInSize?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        fiftyTwoWeekHigh?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        reOfferLowUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        reOfferHighUsd?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctReOfferLow?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctReOfferHigh?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        firstDayTurnover?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferToOneYear?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        pctOfferToPriorQuarter?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        offerToVwap1Day?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        totalManagers?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        totalBookrunners?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        totalNonBookrunners?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        totalPctToBookrunners?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        totalPctToNonBookrunners?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        totalPctToLeftLead?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
        lockUpPeriod?: {
          __typename?: 'Aggregation';
          min?: number | null;
          max?: number | null;
          mean?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GlobalEcmOfferings_Terms_PartFragmentDoc = gql`
  fragment GlobalEcmOfferings_Terms_Part on Term {
    aggregatePrincipalAmount
    aggregatePrincipalInclOverallotmentExercised
    principalAmountOverallotmentAuthorized
    principalAmountPerNote
    couponTalkPercentageLow
    couponTalkPercentageHigh
    premiumTalkLowPercentage
    premiumTalkHighPercentage
    conversionPrice
    conversionRatio
    conversionReferencePrice
    principalAmountOverallotmentExercised
    effectiveConversionPremium
    effectiveConversionPrice
    versusTalkDisplayName
    premiumPercentage
    couponPercentage
    pctOfferPrice
    hasZeroCoupon
  }
`;
export const GlobalEcmOfferings_ListDocument = gql`
  query GlobalEcmOfferings_List(
    $skip: Int
    $take: Int
    $order: [OfferingSortInput!]
    $where: OfferingFilterInput
  ) {
    offerings(skip: $skip, take: $take, order: $order, where: $where) {
      items {
        id
        publicFilingDate
        launchDate
        marketTimingDisplayName
        pricingDate
        firstTradeDate
        settlementDate
        exchangeRegionDisplayName
        exchangeCountryDisplayName
        exchangeMicDisplayName
        pricingCurrencyDisplayName
        hasForwardAgreement
        maturityDate
        issuer {
          cik
          name
          sector
          sectorDisplayName
          subSectorDisplayName
          primarySymbol
          primaryCusip
          primaryIsin
          entityTypes
          headquarters
          naics
        }
        type
        typeDisplayName
        securityType
        securityTypeDisplayName
        isRule144A
        price
        grossSpreadTotal
        settlementDate
        useOfProceeds
        useOfProceedsDisplayNames
        symbol
        pricingCurrency
        managers {
          pctUnderwritingBaseShares
          underwritingBaseShares
          name
          role
          roleDisplayName
          sequence
        }
        advisers {
          role
          roleDisplayName
          name
        }
        attributes {
          priceUsd
          netPrice
          splitAdjustedOfferPrice
          latestGrossProceedsBaseUsd
          latestGrossProceedsTotalUsd
          pctGrossSpread
          overAllotmentExercised
          grossSpreadTotalUsd
          confidentialFilingDate
          latestSizeInSecuritiesBase
          initialSizeInSecuritiesBase
          latestSizeInSecuritiesBasePrimary
          latestSizeInSecuritiesBaseSecondary
          latestPctSecondaryShares
          latestOverAllotmentAuthorized
          overAllotmentExercised
          latestGrossProceedsBase
          latestGrossProceedsBaseUsd
          latestGrossProceedsTotal
          latestGrossProceedsTotalUsd
          initialGrossProceedsBase
          initialGrossProceedsBaseUsd
          revisedGrossProceedsBase
          revisedGrossProceedsBaseUsd
          marketCapAtPricing
          marketCapAtPricingUsd
          marketCapPreOfferingUsd
          latestPostOfferingShares
          preOfferingShares
          pctMarketCapAtPricing
          pctMarketCapPreOffering
          preOfferingAdtv
          sizeAsMultipleOfAdtv
          pctFileToOffer
          pctToLastTrade
          pctToVwap
          pctTo52WeekHigh
          pctGrossSpread
          grossSpreadTotal
          allInCost
          estimatedFeeUsd
          initialIndicativeGrossProceedsUsd
          initialIpoRangeLow
          initialIpoRangeLowUsd
          initialIpoRangeHigh
          initialIpoRangeHighUsd
          priceVsRangeDisplayName
          pctVsMidpoint
          pctChangeInSize
          revisedIpoRangeLowUsd
          revisedIpoRangeHighUsd
          lastTradeBeforeFiling
          lastTradeBeforeFilingUsd
          lastTradeBeforeOffer
          lastTradeBeforeOfferUsd
          fiftyTwoWeekHigh
          reOfferLow
          reOfferLowUsd
          reOfferHigh
          reOfferHighUsd
          pctReOfferLow
          pctReOfferHigh
          firstDayVolume
          firstDayTurnover
          pctOfferToOpen
          offerToVwap1Day
          pctOfferTo1Day
          pctOfferTo3Day
          pctOfferTo7Day
          pctOfferTo14Day
          pctOfferTo30Day
          pctOfferTo90Day
          pctOfferTo180Day
          pctOfferToOneYear
          pctOfferToPriorQuarter
          pctOfferToCurrent
          totalManagers
          totalBookrunners
          totalNonBookrunners
          totalPctToBookrunners
          totalPctToNonBookrunners
          totalPctToLeftLead
          lockUpPeriod
          lockUpExpirationDate
          lockUpEarlyReleaseDate
          isConditionalLockUp
          hasMultipleLockUps
          leftLeadName
          leftLeadId
          primaryShareholderName
          primaryShareholderFirmId
          totalPreOfferingOwnership
          totalPctPreOfferingOwnership
          totalPostOfferingOwnership
          totalPctPostOfferingOwnership
          isFirstFollowOn
          isCarveOut
          isCleanUpTrade
          isConcurrentOffering
          isDualListed
          isEgc
          isSyntheticSecondary
          isUpListing
          useOfProceedsNote
          isCompanyRepurchaseAdditional
          isCompanyRepurchaseIncluded
          hasCornerstoneInvestors
          cornerstoneInvestorsTotalCount
          cornerstoneTotalAmount
          ...OfferingProfile_Common_OfferingAttributes_Part
        }
        initialTermId
        latestRevisedTermId
        finalTermId
        initialTerm {
          ...GlobalEcmOfferings_Terms_Part
        }
        latestRevisedTerm {
          ...GlobalEcmOfferings_Terms_Part
        }
        finalTerm {
          ...GlobalEcmOfferings_Terms_Part
        }
        terms {
          id
          ...GlobalEcmOfferings_Terms_Part
        }
        cornerstoneInvestments {
          ...OfferingProfile_Common_CornerstoneInvestment
        }
        shareholders {
          ...OfferingProfile_Shareholder_Part
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        offset
        pageSize
      }
      summaries {
        aggregations {
          callPrice {
            min
            max
            mean
          }
          conversionPrice {
            min
            max
            mean
          }
          conversionRatio {
            min
            max
            mean
          }
          conversionReferencePrice {
            min
            max
            mean
          }
          pctOfferPrice {
            min
            max
            mean
          }
          priceUsd {
            min
            max
            mean
          }
          couponPercentage {
            min
            max
            mean
          }
          premiumPercentage {
            min
            max
            mean
          }
          aggregatePrincipalAmount {
            min
            max
            mean
          }
          aggregatePrincipalInclOverallotmentExercised {
            min
            max
            mean
          }
          principalAmountPerNote {
            min
            max
            mean
          }
          tenor {
            min
            max
            mean
          }
          pctGrossSpread {
            min
            max
            mean
          }
          pctOfferToOpen {
            min
            max
            mean
          }
          pctOfferTo1Day {
            min
            max
            mean
          }
          pctOfferTo3Day {
            min
            max
            mean
          }
          pctOfferTo7Day {
            min
            max
            mean
          }
          pctOfferTo14Day {
            min
            max
            mean
          }
          pctOfferTo30Day {
            min
            max
            mean
          }
          pctOfferTo90Day {
            min
            max
            mean
          }
          pctOfferTo180Day {
            min
            max
            mean
          }
          pctOfferToCurrent {
            min
            max
            mean
          }
          netPrice {
            min
            max
            mean
          }
          latestSizeInSecuritiesBase {
            min
            max
            mean
          }
          latestPctSecondaryShares {
            min
            max
            mean
          }
          latestGrossProceedsBaseUsd {
            min
            max
            mean
          }
          latestGrossProceedsTotalUsd {
            min
            max
            mean
          }
          marketCapAtPricingUsd {
            min
            max
            mean
          }
          marketCapPreOfferingUsd {
            min
            max
            mean
          }
          latestPostOfferingShares {
            min
            max
            mean
          }
          preOfferingShares {
            min
            max
            mean
          }
          pctMarketCapAtPricing {
            min
            max
            mean
          }
          pctMarketCapPreOffering {
            min
            max
            mean
          }
          sizeAsMultipleOfAdtv {
            min
            max
            mean
          }
          pctFileToOffer {
            min
            max
            mean
          }
          pctToLastTrade {
            min
            max
            mean
          }
          pctToVwap {
            min
            max
            mean
          }
          pctTo52WeekHigh {
            min
            max
            mean
          }
          grossSpreadTotalUsd {
            min
            max
            mean
          }
          allInCost {
            min
            max
            mean
          }
          estimatedFeeUsd {
            min
            max
            mean
          }
          initialIndicativeGrossProceedsUsd {
            min
            max
            mean
          }
          pctVsMidpoint {
            min
            max
            mean
          }
          pctChangeInSize {
            min
            max
            mean
          }
          fiftyTwoWeekHigh {
            min
            max
            mean
          }
          reOfferLowUsd {
            min
            max
            mean
          }
          reOfferHighUsd {
            min
            max
            mean
          }
          pctReOfferLow {
            min
            max
            mean
          }
          pctReOfferHigh {
            min
            max
            mean
          }
          firstDayTurnover {
            min
            max
            mean
          }
          pctOfferToOneYear {
            min
            max
            mean
          }
          pctOfferToPriorQuarter {
            min
            max
            mean
          }
          offerToVwap1Day {
            min
            max
            mean
          }
          totalManagers {
            min
            max
            mean
          }
          totalBookrunners {
            min
            max
            mean
          }
          totalNonBookrunners {
            min
            max
            mean
          }
          totalPctToBookrunners {
            min
            max
            mean
          }
          totalPctToNonBookrunners {
            min
            max
            mean
          }
          totalPctToLeftLead {
            min
            max
            mean
          }
          lockUpPeriod {
            min
            max
            mean
          }
        }
      }
    }
  }
  ${OfferingProfile_Common_OfferingAttributes_PartFragmentDoc}
  ${GlobalEcmOfferings_Terms_PartFragmentDoc}
  ${OfferingProfile_Common_CornerstoneInvestmentFragmentDoc}
  ${OfferingProfile_Shareholder_PartFragmentDoc}
`;

/**
 * __useGlobalEcmOfferings_ListQuery__
 *
 * To run a query within a React component, call `useGlobalEcmOfferings_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalEcmOfferings_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalEcmOfferings_ListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGlobalEcmOfferings_ListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GlobalEcmOfferings_ListQuery,
    GlobalEcmOfferings_ListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalEcmOfferings_ListQuery, GlobalEcmOfferings_ListQueryVariables>(
    GlobalEcmOfferings_ListDocument,
    options
  );
}
export function useGlobalEcmOfferings_ListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalEcmOfferings_ListQuery,
    GlobalEcmOfferings_ListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalEcmOfferings_ListQuery, GlobalEcmOfferings_ListQueryVariables>(
    GlobalEcmOfferings_ListDocument,
    options
  );
}
export function useGlobalEcmOfferings_ListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GlobalEcmOfferings_ListQuery,
        GlobalEcmOfferings_ListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GlobalEcmOfferings_ListQuery,
    GlobalEcmOfferings_ListQueryVariables
  >(GlobalEcmOfferings_ListDocument, options);
}
export type GlobalEcmOfferings_ListQueryHookResult = ReturnType<
  typeof useGlobalEcmOfferings_ListQuery
>;
export type GlobalEcmOfferings_ListLazyQueryHookResult = ReturnType<
  typeof useGlobalEcmOfferings_ListLazyQuery
>;
export type GlobalEcmOfferings_ListSuspenseQueryHookResult = ReturnType<
  typeof useGlobalEcmOfferings_ListSuspenseQuery
>;
export type GlobalEcmOfferings_ListQueryResult = Apollo.QueryResult<
  GlobalEcmOfferings_ListQuery,
  GlobalEcmOfferings_ListQueryVariables
>;
