import { datalabApi } from '@cmg/api';
import { ColDef, CreateCellStyleFn, getCurrencySymbol, numericUtil, timeUtil } from '@cmg/common';
import { Column } from 'ag-grid-community';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import { Link } from 'react-router-dom';

import PerformancePercents from '../../../../common/components/format/performance-percents/PerformancePercents';
import { formatBoolean, isDefined } from '../../../../common/helpers/helpers';
import routeFactory from '../../../../common/util/routeFactory';
import {
  AdviserRole,
  OfferingFilterInput,
  OfferingManagerRole,
  SecurityType,
  SortEnumType,
} from '../../../../graphql/__generated__/index';
import { CategorizedColumn, NestedSortInput } from '../../../../graphql/types';
import HeaderLabelRenderer from '../../../datalab/model/components/HeaderLabelRenderer';
import {
  getCurrencyRangeFormat,
  getFormattedPercentageRange,
  getFormattedPercentageValue,
  managerSortFn,
} from '../../../shared/model/utils';
import { AtmColumnKeys, AtmOffering } from '../../atm-offerings-report/types';
import { ConvertColumnKeys, ConvertOffering } from '../../convert-offerings-report/types';
import { GlobalEcmColumnKeys, GlobalEcmOffering } from '../../global-ecm-v2/types';
import CornerstoneInvestorsRenderer from '../cornerstone-tooltip-renderer/CornerstoneInvestorsRenderer';
import LeftLeadRenderer from '../leftlead-renderer/LeftleadRenderer';
import ManagersRenderer from '../managers-renderer/ManagersRenderer';
import ShareholderRenderer from '../shareholder-renderer/ShareholderRenderer';
import SummaryHeader from '../summary-header/SummaryHeader';
import { aggregationMapping } from './aggregation-mapping';
import { getColumnById, sortModelMappings } from './sort-model';
import { ManagerAttributes, Offering, OfferingAggregations, SharedOfferingGridKeys } from './types';

export const percentagePerformanceFormatter = (
  value?: number | null,
  precision: number = 2
): JSX.Element => <PerformancePercents value={value} precision={precision} />;

const currencyRenderer = ({
  value,
  pricingCurrencyCode,
}: {
  value: number | null;
  pricingCurrencyCode: string | null;
}): string =>
  !isNil(value) && pricingCurrencyCode
    ? numericUtil.formatCurrency(value, 2, getCurrencySymbol(pricingCurrencyCode))
    : '-';
const currencyInMillionRenderer = ({
  value,
  pricingCurrencyCode,
}: {
  value: number | null;
  pricingCurrencyCode: string | null;
}): string =>
  !isNil(value) && pricingCurrencyCode
    ? numericUtil.formatCurrencyInMillions(value, undefined, getCurrencySymbol(pricingCurrencyCode))
    : '-';
// define the columns that should be right aligned
const rightAligned = {
  type: 'rightAligned',
};

const booleanValueFormatter = (value?: boolean | null) =>
  value !== null && value !== undefined ? formatBoolean(value) : '-';

const booleanColumn = {
  valueFormatter: ({ value }) => booleanValueFormatter(value as boolean | undefined),
};

const formatDate = (v?: string | Date | null): string =>
  timeUtil.formatAsDisplayDate(v ?? '') ?? '-';

const dateColumn = {
  valueFormatter: ({ value }) => formatDate(value as Date | string),
};

const getAdviserNameByRole = (advisers: ConvertOffering['advisers'], role: AdviserRole) => {
  const advisersByRole = advisers?.filter(adviser => adviser.role === role) ?? [];
  return advisersByRole.length > 0 ? advisersByRole.map(adviser => adviser?.name).join(', ') : '-';
};

const getManagerNameByRole = (
  managers: ManagerAttributes[],
  role: OfferingManagerRole
): ManagerAttributes[] => {
  return managers.filter(manager => manager.role === role);
};

const managerRenderer = (managerNames?: readonly ManagerAttributes[]): string => {
  const sortedManagers = managerNames?.slice().sort(managerSortFn) ?? [];
  return sortedManagers.length > 0 ? sortedManagers.map(manager => manager?.name).join(', ') : '-';
};

const stringValueFormatter = (value?: string | null): string => value ?? '-';

const stringValueColumn = {
  valueFormatter: ({ value }) => stringValueFormatter(value),
};

export const convertColumns: Record<
  string,
  ColDef<ConvertOffering> & { field: ConvertColumnKeys }
> = {
  pricingDate: {
    field: 'pricingDate',
    headerName: 'Pricing Date',
    sortingOrder: ['asc', null],
    ...dateColumn,
  },
  convertPublicFilingDate: {
    field: 'publicFilingDate',
    headerName: 'Public Filing Date',
    hide: true,
    ...dateColumn,
  },
  launchDate: {
    field: 'launchDate',
    headerName: 'Launch Date',
    hide: true,
    ...dateColumn,
  },
  firstTradeDate: {
    field: 'firstTradeDate',
    headerName: 'First Trade Date',
    hide: true,
    ...dateColumn,
  },
  timingOfLaunch: {
    field: 'marketTimingDisplayName',
    headerName: 'Launch Timing',
    hide: true,
    ...stringValueColumn,
  },
  settlementDate: {
    field: 'settlementDate',
    headerName: 'Settlement Date',
    ...dateColumn,
    hide: true,
  },
  exchangeMicDisplayName: {
    field: 'exchangeMicDisplayName',
    headerName: 'Underlying Exchange',
    ...stringValueColumn,
    hide: true,
  },
  exchangeRegionDisplayName: {
    field: 'exchangeRegionDisplayName',
    headerName: 'Underlying Exchange Region',
    ...stringValueColumn,
    hide: true,
  },
  exchangeCountryDisplayName: {
    field: 'exchangeCountryDisplayName',
    headerName: 'Underlying Exchange Country',
    ...stringValueColumn,
    hide: true,
  },
  typeDisplayName: {
    field: 'typeDisplayName',
    headerName: 'Type',
    ...stringValueColumn,
    // display enum label
    hide: true,
  },
  pricingCurrencyDisplayName: {
    field: 'pricingCurrencyDisplayName',
    headerName: 'Currency',
    ...stringValueColumn,
    hide: true,
  },
  isRule144A: {
    field: 'isRule144A',
    headerName: 'Rule 144A',
    ...booleanColumn,
  },
  attributes_latestGrossProceedsBaseUsd: {
    field: 'attributes_latestGrossProceedsBaseUsd',
    headerName: 'Gross Proceeds Base',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsBaseUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
    hide: true,
  },
  maturityDate: {
    field: 'maturityDate',
    headerName: 'Maturity',
    ...dateColumn,
  },
  convertibleAttributes_underlyingSymbol: {
    field: 'convertibleAttributes_underlyingSymbol',
    headerName: 'Underlying Symbol',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.underlyingSymbol),
  },
  convertibleAttributes_securityNote: {
    field: 'convertibleAttributes_securityNote',
    maxWidth: 200,
    headerName: 'Instrument Description',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.securityNote),
  },
  convertibleAttributes_rank: {
    field: 'convertibleAttributes_rankDisplayName',
    headerName: 'Rank',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.rankDisplayName),
  },
  finalTerm_pctOfferPrice: {
    field: 'finalTerm_pctOfferPrice',
    headerName: 'Offer Price (%)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.finalTerm?.pctOfferPrice),
    ...rightAligned,
    sortable: false,
  },
  attributes_priceUsd: {
    field: 'attributes_priceUsd',
    headerName: 'Offer Price ($)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.attributes?.priceUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  finalTerm_couponPercentage: {
    field: 'finalTerm_couponPercentage',
    headerName: 'Coupon',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.finalTerm?.couponPercentage, 3),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_premiumPercentage: {
    field: 'finalTerm_premiumPercentage',
    headerName: 'Premium',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.finalTerm?.premiumPercentage),
    ...rightAligned,
    sortable: false,
  },
  initialTerm_couponTalkPercentageRange: {
    field: 'initialTerm_couponTalkPercentageRange',
    headerName: 'Coupon Talk (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageRange(
        data?.initialTerm?.couponTalkPercentageLow,
        data?.initialTerm?.couponTalkPercentageHigh,
        3
      ),
    ...rightAligned,
    sortable: false,
  },
  initialTerm_premiumTalkPercentageRange: {
    field: 'initialTerm_premiumTalkPercentageRange',
    headerName: 'Premium Talk (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageRange(
        data?.initialTerm?.premiumTalkLowPercentage,
        data?.initialTerm?.premiumTalkHighPercentage
      ),
    ...rightAligned,
    sortable: false,
  },
  latestRevisedTerm_couponTalkPercentageRange: {
    field: 'latestRevisedTerm_couponTalkPercentageRange',
    headerName: 'Coupon Talk (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageRange(
        data?.latestRevisedTerm?.couponTalkPercentageLow,
        data?.latestRevisedTerm?.couponTalkPercentageHigh,
        3
      ),
    ...rightAligned,
    sortable: false,
    hide: true,
  },
  latestRevisedTerm_premiumTalkPercentageRange: {
    field: 'latestRevisedTerm_premiumTalkPercentageRange',
    headerName: 'Premium Talk (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageRange(
        data?.latestRevisedTerm?.premiumTalkLowPercentage,
        data?.latestRevisedTerm?.premiumTalkHighPercentage
      ),
    ...rightAligned,
    sortable: false,
    hide: true,
  },
  finalTerm_aggregatePrincipalAmount: {
    field: 'finalTerm_aggregatePrincipalAmount',
    headerName: 'Principal Amount',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.finalTerm?.aggregatePrincipalAmount ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  initialTerm_aggregatePrincipalAmount: {
    field: 'initialTerm_aggregatePrincipalAmount',
    headerName: 'Principal Amount (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.initialTerm?.aggregatePrincipalAmount ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  latestRevisedTerm_aggregatePrincipalAmount: {
    field: 'latestRevisedTerm_aggregatePrincipalAmount',
    headerName: 'Principal Amount (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.latestRevisedTerm?.aggregatePrincipalAmount ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  finalTerm_principalAmountOverallotmentAuthorized: {
    field: 'finalTerm_principalAmountOverallotmentAuthorized',
    headerName: 'Principal Amount Ovlt (Authorized)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.finalTerm?.principalAmountOverallotmentAuthorized ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  initialTerm_principalAmountOverallotmentAuthorized: {
    field: 'initialTerm_principalAmountOverallotmentAuthorized',
    headerName: 'Principal Amount Ovlt (Authorized) (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.initialTerm?.principalAmountOverallotmentAuthorized ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  latestRevisedTerm_principalAmountOverallotmentAuthorized: {
    field: 'latestRevisedTerm_principalAmountOverallotmentAuthorized',
    headerName: 'Principal Amount Ovlt (Authorized) (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.latestRevisedTerm?.principalAmountOverallotmentAuthorized ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  finalTerm_principalAmountOverallotmentExercised: {
    field: 'finalTerm_principalAmountOverallotmentExercised',
    headerName: 'Principal Amount Ovlt (Exercised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.finalTerm?.principalAmountOverallotmentExercised ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  finalTerm_aggregatePrincipalInclOverallotmentExercised: {
    field: 'finalTerm_aggregatePrincipalInclOverallotmentExercised',
    headerName: 'Principal Amount (Inc. Ovlt. Exercised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.finalTerm?.aggregatePrincipalInclOverallotmentExercised ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_principalAmountPerNote: {
    field: 'finalTerm_principalAmountPerNote',
    headerName: 'Par Value',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.finalTerm?.principalAmountPerNote ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  initialTerm_principalAmountPerNote: {
    field: 'initialTerm_principalAmountPerNote',
    headerName: 'Par Value (initial)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.initialTerm?.principalAmountPerNote ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  latestRevisedTerm_principalAmountPerNote: {
    field: 'latestRevisedTerm_principalAmountPerNote',
    headerName: 'Par Value (revised)',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.latestRevisedTerm?.principalAmountPerNote ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  //   attributes_notionalOverAllotmentExercised: {
  //     field: 'attributes_notionalOverAllotmentExercised',
  //     headerName: 'Overallotment Exercised Proceeds',
  //     valueGetter: ({ data }: { data?: ConvertOffering }) =>
  //       currencyRenderer({
  //         value: data?.attributes?.notionalOverAllotmentExercised ?? null,
  //         pricingCurrencyCode: data?.currency ?? null,
  //       }),
  //     ...rightAligned,
  //     hide: true,
  //   }, // this field is not available yet
  attributes_pctGrossSpread: {
    field: 'attributes_pctGrossSpread',
    headerName: 'Gross Spread (%) ',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctGrossSpread),
    ...rightAligned,
  },
  attributes_grossSpreadTotalUsd: {
    field: 'attributes_grossSpreadTotalUsd',
    headerName: 'Gross Spread',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.attributes?.grossSpreadTotalUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
    hide: true,
  },
  finalTerm_versusTalkDisplayName: {
    field: 'finalTerm_versusTalkDisplayName',
    headerName: 'Versus Talk',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.finalTerm?.versusTalkDisplayName),
    hide: true,
    sortable: false,
  },
  finalTerm_conversionPrice: {
    field: 'finalTerm_conversionPrice',
    headerName: 'Conversion Price',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.finalTerm?.conversionPrice ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_conversionRatio: {
    field: 'finalTerm_conversionRatio',
    headerName: 'Initial Conversion Ratio',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      numericUtil.getDisplayValueForNumber(data?.finalTerm?.conversionRatio),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_conversionReferencePrice: {
    field: 'finalTerm_conversionReferencePrice',
    headerName: 'Conversion Reference Price',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.finalTerm?.conversionReferencePrice ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    sortable: false,
  },
  finalTerm_hasZeroCoupon: {
    field: 'finalTerm_hasZeroCoupon',
    headerName: 'Zero Coupon',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.finalTerm?.hasZeroCoupon),
    hide: true,
    sortable: false,
  },
  convertibleAttributes_hasDividendProtection: {
    field: 'convertibleAttributes_hasDividendProtection',
    headerName: 'Dividend Protection',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.hasDividendProtection),
  },
  convertibleAttributes_dividendProtectionNote: {
    field: 'convertibleAttributes_dividendProtectionNote',
    headerName: 'Dividend Protection Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.dividendProtectionNote),
  },
  convertibleAttributes_isCallable: {
    field: 'convertibleAttributes_isCallable',
    headerName: 'Callable',
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.isCallable),
  },
  convertibleAttributes_callableDate: {
    field: 'convertibleAttributes_callableDate',
    headerName: 'Callable Date',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      formatDate(data?.convertibleAttributes?.callableDate),
  },
  convertibleAttributes_callPrice: {
    field: 'convertibleAttributes_callPrice',
    headerName: 'Call Price',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.convertibleAttributes?.callPrice),
    ...rightAligned,
  },
  convertibleAttributes_hasProvisionalCall: {
    field: 'convertibleAttributes_hasProvisionalCall',
    headerName: 'Provisional Call',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.hasProvisionalCall),
  },
  convertibleAttributes_provisionalCallNote: {
    field: 'convertibleAttributes_provisionalCallNote',
    headerName: 'Provisional Call Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.provisionalCallNote),
  },
  convertibleAttributes_isPuttable: {
    field: 'convertibleAttributes_isPuttable',
    headerName: 'Put',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.isPuttable),
  },
  convertibleAttributes_putNote: {
    field: 'convertibleAttributes_putNote',
    headerName: 'Put Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.putNote),
  },
  convertibleAttributes_hasContingentConversion: {
    field: 'convertibleAttributes_hasContingentConversion',
    headerName: 'Contingent Conversion',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.hasContingentConversion),
  },
  convertibleAttributes_contingentConversionNote: {
    field: 'convertibleAttributes_contingentConversionNote',
    headerName: 'Contingent Conversion Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.contingentConversionNote),
  },
  convertibleAttributes_hasContingentPayment: {
    field: 'convertibleAttributes_hasContingentPayment',
    headerName: 'Contingent Payment',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.hasContingentPayment),
  },
  convertibleAttributes_contingentPaymentNote: {
    field: 'convertibleAttributes_contingentPaymentNote',
    headerName: 'Contingent Payment Note',
    maxWidth: 200,
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.contingentPaymentNote),
  },
  convertibleAttributes_isHedging: {
    field: 'convertibleAttributes_isHedging',
    headerName: 'Hedging',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.isHedging),
  },
  convertibleAttributes_hedgingNote: {
    field: 'convertibleAttributes_hedgingNote',
    maxWidth: 200,
    headerName: 'Hedging Note',
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.hedgingNote),
  },
  finalTerm_effectiveConversionPremium: {
    field: 'finalTerm_effectiveConversionPremium',
    headerName: 'Effective Conversion Premium',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getFormattedPercentageValue(data?.finalTerm?.effectiveConversionPremium),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  finalTerm_effectiveConversionPrice: {
    field: 'finalTerm_effectiveConversionPrice',
    headerName: 'Effective Conversion Price',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyRenderer({
        value: data?.finalTerm?.effectiveConversionPrice ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
    hide: true,
    sortable: false,
  },
  useOfProceedsDisplayNames: {
    field: 'useOfProceedsDisplayNames',
    headerName: 'Use Of Proceeds',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      data?.useOfProceedsDisplayNames?.length ? data?.useOfProceedsDisplayNames?.join(', ') : '-',
    hide: true,
    sortable: false,
  },
  convertibleAttributes_changeOfControl: {
    field: 'convertibleAttributes_changeOfControl',
    headerName: 'Change Of Control Note',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      stringValueFormatter(data?.convertibleAttributes?.changeOfControl),
    hide: true,
  },
  trustee: {
    field: 'trustee',
    headerName: 'Trustee',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getAdviserNameByRole(data?.advisers ?? [], AdviserRole.Trustee),
    hide: true,
    sortable: false,
  },
  issuerCounsel: {
    field: 'issuerCounsel',
    headerName: 'Issuer Counsel', // Counsil
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      getAdviserNameByRole(data?.advisers ?? [], AdviserRole.IssuerCounsel),
    hide: true,
    sortable: false,
  },
  attributes_latestGrossProceedsTotalUsd: {
    field: 'attributes_latestGrossProceedsTotalUsd',
    headerName: 'Gross Proceeds Total',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsTotalUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
    hide: true,
  },
  convertibleAttributes_isPerpetual: {
    field: 'convertibleAttributes_isPerpetual',
    headerName: 'Perpetual',
    hide: true,
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      booleanValueFormatter(data?.convertibleAttributes?.isPerpetual),
  },
  convertibleAttributes_tenor: {
    field: 'convertibleAttributes_tenor',
    headerName: 'Tenor',
    valueGetter: ({ data }: { data?: ConvertOffering }) =>
      numericUtil.getDisplayValueForNumber(data?.convertibleAttributes?.tenor),
    ...rightAligned,
  },
};

export const atmColumns: Record<string, ColDef<AtmOffering> & { field: AtmColumnKeys }> = {
  announcementDate: {
    field: 'publicFilingDate',
    headerName: 'Announcement Date',
    ...dateColumn,
  },
  announcementTime: {
    field: 'marketTimingDisplayName',
    headerName: 'Announcement Time',
    ...stringValueColumn,
  },
  atmAttributes_effectiveDate: {
    field: 'atmAttributes_effectiveDate',
    headerName: 'Effective Date',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      formatDate(data?.atmAttributes?.effectiveDate),
  },
  atmAttributes_pctGrossSpread: {
    field: 'atmAttributes_pctGrossSpread',
    headerName: 'Gross Spread (%)',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.atmAttributes?.pctGrossSpread),
    ...rightAligned,
  },
  atmAttributes_totalProgramRemaining: {
    field: 'atmAttributes_totalProgramRemaining',
    headerName: 'ATM Program Remaining',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.atmAttributes?.totalProgramRemaining ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
  },
  atmAttributes_totalProgramRemainingInSecurities: {
    field: 'atmAttributes_totalProgramRemainingInSecurities',
    headerName: 'ATM Program Remaining Securities',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      data?.atmAttributes?.totalProgramRemainingInSecurities
        ? numericUtil.formatInteger(data?.atmAttributes?.totalProgramRemainingInSecurities)
        : '-',
    ...rightAligned,
  },
  atmAttributes_latestProgramSize: {
    field: 'atmAttributes_latestProgramSize',
    headerName: 'ATM Program Size',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.atmAttributes?.latestProgramSize ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
  },
  atmAttributes_latestProgramSizeInSecurities: {
    field: 'atmAttributes_latestProgramSizeInSecurities',
    headerName: 'ATM Program Size In Securities',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      data?.atmAttributes?.latestProgramSizeInSecurities
        ? numericUtil.formatInteger(data?.atmAttributes?.latestProgramSizeInSecurities)
        : '-',
    ...rightAligned,
  },
  atmAttributes_announcedProgramSize: {
    field: 'atmAttributes_announcedProgramSize',
    headerName: 'ATM Program Size - Announced',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.atmAttributes?.announcedProgramSize ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    hide: true,
    ...rightAligned,
  },
  atmAttributes_announcedProgramSizeInSecurities: {
    field: 'atmAttributes_announcedProgramSizeInSecurities',
    headerName: 'ATM Program Size In Securities - Announced',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      data?.atmAttributes?.announcedProgramSizeInSecurities
        ? numericUtil.formatInteger(data?.atmAttributes?.announcedProgramSizeInSecurities)
        : '-',
    hide: true,
    ...rightAligned,
  },
  attributes_latestSizeInSecuritiesTotal: {
    field: 'attributes_latestSizeInSecuritiesTotal',
    headerName: 'Size in Securities Total',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      numericUtil.getDisplayValueForNumber(data?.attributes?.latestSizeInSecuritiesTotal, 0),
    ...rightAligned,
  },
  attributes_latestGrossProceedsTotal: {
    field: 'attributes_latestGrossProceedsTotal',
    headerName: 'Gross Proceeds Total',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsTotal ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_marketCapPreOffering: {
    field: 'attributes_marketCapPreOffering',
    headerName: 'Pre-Offering Mkt. Cap',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.marketCapPreOffering ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_pctMarketCapPreOffering: {
    field: 'attributes_pctMarketCapPreOffering',
    headerName: '% of Pre-Offering Market Cap',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctMarketCapPreOffering),
    ...rightAligned,
  },
  attributes_preOfferingAdtv: {
    field: 'attributes_preOfferingAdtv',
    headerName: '30 Day ADTV',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      isDefined(data?.attributes?.preOfferingAdtv)
        ? numericUtil.formatInteger(data?.attributes?.preOfferingAdtv)
        : '-',
    ...rightAligned,
  },
  attributes_terminatedDate: {
    field: 'attributes_terminatedDate',
    headerName: 'Terminated Date',
    valueGetter: ({ data }: { data?: AtmOffering }) => formatDate(data?.attributes?.terminatedDate),
  },
  attributes_lastTradeBeforeFilingUsd: {
    field: 'attributes_lastTradeBeforeFilingUsd',
    headerName: 'Last Trade Before Filing',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.lastTradeBeforeFilingUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  atmAttributes_lastTradeBeforeFilingSplitAdjusted: {
    field: 'atmAttributes_lastTradeBeforeFilingSplitAdjusted',
    headerName: 'Last Trade Before Filing Split Adjusted',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      currencyRenderer({
        value: data?.atmAttributes?.lastTradeBeforeFilingSplitAdjusted ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_pctOfferToOpen: {
    field: 'attributes_pctOfferToOpen',
    headerName: 'Open',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferToOpen),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferToOpen),
    ...rightAligned,
  },
  attributes_pctOfferTo1Day: {
    field: 'attributes_pctOfferTo1Day',
    headerName: '1 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo1Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo1Day),
    ...rightAligned,
  },
  attributes_pctOfferTo3Day: {
    field: 'attributes_pctOfferTo3Day',
    headerName: '3 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo3Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo3Day),
    ...rightAligned,
    hide: true,
  },
  attributes_pctOfferTo7Day: {
    field: 'attributes_pctOfferTo7Day',
    headerName: '7 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo7Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo7Day),
    ...rightAligned,
  },
  attributes_pctOfferTo14Day: {
    field: 'attributes_pctOfferTo14Day',
    headerName: '14 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo14Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo14Day),
    ...rightAligned,
    hide: true,
  },
  attributes_pctOfferTo30Day: {
    field: 'attributes_pctOfferTo30Day',
    headerName: '30 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo30Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo30Day),
    ...rightAligned,
  },
  attributes_pctOfferTo90Day: {
    field: 'attributes_pctOfferTo90Day',
    headerName: '90 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo90Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo90Day),
    ...rightAligned,
    hide: true,
  },
  attributes_pctOfferTo180Day: {
    field: 'attributes_pctOfferTo180Day',
    headerName: '180 Day',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferTo180Day),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferTo180Day),
    ...rightAligned,
  },
  attributes_pctOfferToCurrent: {
    field: 'attributes_pctOfferToCurrent',
    headerName: 'Current',
    valueGetter: ({ data }: { data?: AtmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferToCurrent),
    cellRendererFramework: ({ data }: { data?: AtmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferToCurrent),
    ...rightAligned,
  },
};

export const globalEcmColumns: Record<
  string,
  ColDef<GlobalEcmOffering> & { field: GlobalEcmColumnKeys }
> = {
  issuer_primaryCusip: {
    field: 'issuer_primaryCusip',
    headerName: 'CUSIP',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      stringValueFormatter(data?.issuer?.primaryCusip),
    hide: true,
    sortable: false,
  },
  issuer_primaryIsin: {
    field: 'issuer_primaryIsin',
    headerName: 'ISIN',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      stringValueFormatter(data?.issuer?.primaryIsin),
    hide: true,
    sortable: false,
  },
  // made a copy here so it's not right aligned
  issuer_cik: {
    field: 'issuer_cik',
    headerName: 'CIK',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      stringValueFormatter(data?.issuer?.cik),
  },
  attributes_confidentialFilingDate: {
    field: 'attributes_confidentialFilingDate',
    headerName: 'Confidential Filing Date',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      formatDate(data?.attributes?.confidentialFilingDate),
  },
  issuer_entityTypes: {
    field: 'issuer_entityTypes',
    headerName: 'Structure',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      data?.issuer?.entityTypes?.length ? data?.issuer?.entityTypes?.join(', ') : '-',
    hide: true,
    sortable: false,
  },
  exchangeMicDisplayName: {
    field: 'exchangeMicDisplayName',
    headerName: 'Exchange',
    ...stringValueColumn,
    hide: true,
  },
  exchangeRegionDisplayName: {
    field: 'exchangeRegionDisplayName',
    headerName: 'Exchange Region',
    ...stringValueColumn,
    hide: true,
  },
  exchangeCountryDisplayName: {
    field: 'exchangeCountryDisplayName',
    headerName: 'Exchange Country',
    ...stringValueColumn,
    hide: true,
  },
  issuer_subSectorDisplayName: {
    field: 'issuer_subSectorDisplayName',
    headerName: 'Sub Sector',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      stringValueFormatter(data?.issuer?.subSectorDisplayName),
  },
  price: {
    field: 'price',
    headerName: 'Offer Price',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.price ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_priceUsd: {
    field: 'attributes_priceUsd',
    headerName: 'Offer Price',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.priceUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_netPrice: {
    field: 'attributes_netPrice',
    headerName: 'Net Price',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.netPrice ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_splitAdjustedOfferPrice: {
    field: 'attributes_splitAdjustedOfferPrice',
    headerName: 'Split Adjusted Offering Price',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.splitAdjustedOfferPrice ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
  },
  attributes_latestSizeInSecuritiesBase: {
    field: 'attributes_latestSizeInSecuritiesBase',
    headerName: 'Total Shares',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.latestSizeInSecuritiesBase)
        ? numericUtil.formatInteger(data?.attributes?.latestSizeInSecuritiesBase)
        : '-',
    ...rightAligned,
  },
  attributes_initialSizeInSecuritiesBase: {
    field: 'attributes_initialSizeInSecuritiesBase',
    headerName: 'Total Shares Filed (excl shoe)',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.initialSizeInSecuritiesBase)
        ? numericUtil.formatInteger(data?.attributes?.initialSizeInSecuritiesBase)
        : '-',
    ...rightAligned,
  },
  attributes_latestSizeInSecuritiesBasePrimary: {
    field: 'attributes_latestSizeInSecuritiesBasePrimary',
    headerName: 'Primary Shares Base Offering',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.latestSizeInSecuritiesBasePrimary)
        ? numericUtil.formatInteger(data?.attributes?.latestSizeInSecuritiesBasePrimary)
        : '-',
    ...rightAligned,
  },
  attributes_latestSizeInSecuritiesBaseSecondary: {
    field: 'attributes_latestSizeInSecuritiesBaseSecondary',
    headerName: 'Secondary Shares Base Offering',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.latestSizeInSecuritiesBaseSecondary)
        ? numericUtil.formatInteger(data?.attributes?.latestSizeInSecuritiesBaseSecondary)
        : '-',
    ...rightAligned,
  },
  attributes_latestPctSecondaryShares: {
    field: 'attributes_latestPctSecondaryShares',
    headerName: '% Secondary',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.latestPctSecondaryShares, 1),
    ...rightAligned,
  },
  attributes_latestOverAllotmentAuthorized: {
    field: 'attributes_latestOverAllotmentAuthorized',
    headerName: 'Over-allotment Authorized',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.latestOverAllotmentAuthorized)
        ? numericUtil.formatInteger(data?.attributes?.latestOverAllotmentAuthorized)
        : '-',
    ...rightAligned,
  },
  attributes_overAllotmentExercised: {
    field: 'attributes_overAllotmentExercised',
    headerName: 'Over-allotment Excercised',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.overAllotmentExercised)
        ? numericUtil.formatInteger(data?.attributes?.overAllotmentExercised)
        : '-',
    ...rightAligned,
  },
  attributes_latestGrossProceedsBase: {
    field: 'attributes_latestGrossProceedsBase',
    headerName: 'Gross Proceeds Base',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsBase ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_latestGrossProceedsBaseUsd: {
    field: 'attributes_latestGrossProceedsBaseUsd',
    headerName: 'Gross Proceeds Base',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsBaseUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_latestGrossProceedsTotal: {
    field: 'attributes_latestGrossProceedsTotal',
    headerName: 'Gross Proceeds Total',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsTotal ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_latestGrossProceedsTotalUsd: {
    field: 'attributes_latestGrossProceedsTotalUsd',
    headerName: 'Gross Proceeds Total',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.latestGrossProceedsTotalUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_initialGrossProceedsBase: {
    field: 'attributes_initialGrossProceedsBase',
    headerName: 'Gross Proceeds Base (Initial)',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.initialGrossProceedsBase ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_initialGrossProceedsBaseUsd: {
    field: 'attributes_initialGrossProceedsBaseUsd',
    headerName: 'Gross Proceeds Base (Initial)',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.initialGrossProceedsBaseUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_revisedGrossProceedsBase: {
    field: 'attributes_revisedGrossProceedsBase',
    headerName: 'Gross Proceeds Base (Revised)',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.revisedGrossProceedsBase ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_revisedGrossProceedsBaseUsd: {
    field: 'attributes_revisedGrossProceedsBaseUsd',
    headerName: 'Gross Proceeds Base (Revised)',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.revisedGrossProceedsBaseUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_marketCapAtPricing: {
    field: 'attributes_marketCapAtPricing',
    headerName: 'Post-Offering Mkt. Cap',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.marketCapAtPricing ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_marketCapAtPricingUsd: {
    field: 'attributes_marketCapAtPricingUsd',
    headerName: 'Post-Offering Mkt. Cap',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.marketCapAtPricingUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_marketCapPreOfferingUsd: {
    field: 'attributes_marketCapPreOfferingUsd',
    headerName: 'Pre-Offering Mkt. Cap',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.marketCapPreOfferingUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_latestPostOfferingShares: {
    field: 'attributes_latestPostOfferingShares',
    headerName: 'Post Offering Shares Outstanding',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.latestPostOfferingShares)
        ? numericUtil.formatInteger(data?.attributes?.latestPostOfferingShares)
        : '-',
    ...rightAligned,
  },
  attributes_preOfferingShares: {
    field: 'attributes_preOfferingShares',
    headerName: 'Pre Offering Shares Outstanding',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.preOfferingShares)
        ? numericUtil.formatInteger(data?.attributes?.preOfferingShares)
        : '-',
    ...rightAligned,
  },
  attributes_pctMarketCapAtPricing: {
    field: 'attributes_pctMarketCapAtPricing',
    headerName: '% of Market Cap',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctMarketCapAtPricing, 1),
    ...rightAligned,
  },
  attributes_pctMarketCapPreOffering: {
    field: 'attributes_pctMarketCapPreOffering',
    headerName: '% of Pre-Offering Market Cap',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctMarketCapPreOffering, 1),
    ...rightAligned,
  },
  attributes_preOfferingAdtv: {
    field: 'attributes_preOfferingAdtv',
    headerName: 'ADTV',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.preOfferingAdtv)
        ? numericUtil.formatInteger(data?.attributes?.preOfferingAdtv)
        : '-',
    ...rightAligned,
  },
  attributes_sizeAsMultipleOfAdtv: {
    field: 'attributes_sizeAsMultipleOfAdtv',
    headerName: 'Mult. of ADTV',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.sizeAsMultipleOfAdtv)
        ? numericUtil.formatMultipleFactor(data?.attributes?.sizeAsMultipleOfAdtv)
        : '-',
    ...rightAligned,
  },
  attributes_pctFileToOffer: {
    field: 'attributes_pctFileToOffer',
    headerName: 'File to Offer %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctFileToOffer),
    ...rightAligned,
  },
  attributes_pctToLastTrade: {
    field: 'attributes_pctToLastTrade',
    headerName: 'To Last Trade %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctToLastTrade),
    ...rightAligned,
  },
  attributes_pctToVwap: {
    field: 'attributes_pctToVwap',
    headerName: 'To VWAP %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctToVwap),
    ...rightAligned,
  },
  attributes_pctTo52WeekHigh: {
    field: 'attributes_pctTo52WeekHigh',
    headerName: 'To 52-Week High %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctTo52WeekHigh),
    ...rightAligned,
  },
  attributes_pctGrossSpread: {
    field: 'attributes_pctGrossSpread',
    headerName: 'Gross Spread %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctGrossSpread),
    ...rightAligned,
  },
  attributes_allInCost: {
    field: 'attributes_allInCost',
    headerName: 'All-in %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.allInCost),
    ...rightAligned,
  },
  attributes_estimatedFeeUsd: {
    field: 'attributes_estimatedFeeUsd',
    headerName: 'Estimated Fee',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.estimatedFeeUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_initialIndicativeGrossProceedsUsd: {
    field: 'attributes_initialIndicativeGrossProceedsUsd',
    headerName: 'Initial Registration Value',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.initialIndicativeGrossProceedsUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_initialIpoRange: {
    field: 'attributes_initialIpoRange',
    headerName: 'Initial IPO Range',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      `${data?.attributes?.initialIpoRangeLow} - ${data?.attributes?.initialIpoRangeHigh}`,
    cellRendererFramework: ({ data }: { data?: GlobalEcmOffering }) =>
      getCurrencyRangeFormat({
        valueLow: data?.attributes?.initialIpoRangeLow,
        valueHigh: data?.attributes?.initialIpoRangeHigh,
        pricingCurrencyCode: data?.pricingCurrency as string,
        showInternational: true,
      }),
    ...rightAligned,
    sortable: false,
  },
  attributes_initialIpoRangeUsd: {
    field: 'attributes_initialIpoRangeUsd',
    headerName: 'Initial IPO Range',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      `${data?.attributes?.initialIpoRangeLowUsd} - ${data?.attributes?.initialIpoRangeHighUsd}`,
    cellRendererFramework: ({ data }: { data?: GlobalEcmOffering }) =>
      getCurrencyRangeFormat({
        valueLow: data?.attributes?.initialIpoRangeLowUsd,
        valueHigh: data?.attributes?.initialIpoRangeHighUsd,
        pricingCurrencyCode: 'USD',
        showInternational: true,
      }),
    ...rightAligned,
    sortable: false,
  },
  attributes_priceVsRangeDisplayName: {
    field: 'attributes_priceVsRangeDisplayName',
    headerName: 'Price vs. Range',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      stringValueFormatter(data?.attributes?.priceVsRangeDisplayName),
  },
  attributes_pctVsMidpoint: {
    field: 'attributes_pctVsMidpoint',
    headerName: '% to Midpoint',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctVsMidpoint, 1),
    ...rightAligned,
  },
  attributes_pctChangeInSize: {
    field: 'attributes_pctChangeInSize',
    headerName: '% Change In Size',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctChangeInSize, 1),
    ...rightAligned,
  },
  attributes_revisedIpoRangeUsd: {
    field: 'attributes_revisedIpoRangeUsd',
    headerName: 'Revised IPO Range',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      `${data?.attributes?.revisedIpoRangeLowUsd} - ${data?.attributes?.revisedIpoRangeHighUsd}`,
    cellRendererFramework: ({ data }: { data?: GlobalEcmOffering }) =>
      getCurrencyRangeFormat({
        valueLow: data?.attributes?.revisedIpoRangeLowUsd,
        valueHigh: data?.attributes?.revisedIpoRangeHighUsd,
        pricingCurrencyCode: 'USD',
        showInternational: true,
      }),
    ...rightAligned,
    sortable: false,
  },
  attributes_lastTradeBeforeFiling: {
    field: 'attributes_lastTradeBeforeFiling',
    headerName: 'Last Trade Before Filing',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.lastTradeBeforeFiling ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_lastTradeBeforeFilingUsd: {
    field: 'attributes_lastTradeBeforeFilingUsd',
    headerName: 'Last Trade Before Filing',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.lastTradeBeforeFilingUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_lastTradeBeforeOffer: {
    field: 'attributes_lastTradeBeforeOffer',
    headerName: 'Last Trade Before Offer',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.lastTradeBeforeOffer ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_lastTradeBeforeOfferUsd: {
    field: 'attributes_lastTradeBeforeOfferUsd',
    headerName: 'Last Trade Before Offer',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.lastTradeBeforeOfferUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_fiftyTwoWeekHigh: {
    field: 'attributes_fiftyTwoWeekHigh',
    headerName: '52-Week High',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.fiftyTwoWeekHigh ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    ...rightAligned,
  },
  attributes_reOfferLow: {
    field: 'attributes_reOfferLow',
    headerName: 'Re Offer Low',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.reOfferLow ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_reOfferLowUsd: {
    field: 'attributes_reOfferLowUsd',
    headerName: 'Re Offer Low',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.reOfferLowUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_reOfferHigh: {
    field: 'attributes_reOfferHigh',
    headerName: 'Re Offer High',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.reOfferHigh ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
  attributes_reOfferHighUsd: {
    field: 'attributes_reOfferHighUsd',
    headerName: 'Re Offer High',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyRenderer({
        value: data?.attributes?.reOfferHighUsd ?? null,
        pricingCurrencyCode: 'USD',
      }),
    ...rightAligned,
  },
  attributes_pctReOfferHigh: {
    field: 'attributes_pctReOfferHigh',
    headerName: 'Re Offer High %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctReOfferHigh),
    ...rightAligned,
  },
  attributes_pctReOfferLow: {
    field: 'attributes_pctReOfferLow',
    headerName: 'Re Offer Low %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctReOfferLow),
    ...rightAligned,
  },
  attributes_firstDayVolume: {
    field: 'attributes_firstDayVolume',
    headerName: 'First Day Volume',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.firstDayVolume)
        ? numericUtil.formatInteger(data?.attributes?.firstDayVolume)
        : '-',
    ...rightAligned,
  },
  attributes_firstDayTurnover: {
    field: 'attributes_firstDayTurnover',
    headerName: 'First Day Turnover',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.firstDayTurnover)
        ? numericUtil.formatMultipleFactor(data?.attributes?.firstDayTurnover)
        : '-',
    ...rightAligned,
  },
  attributes_offerToVwap1Day: {
    field: 'attributes_offerToVwap1Day',
    headerName: '1 Day VWAP',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.offerToVwap1Day),
    cellRendererFramework: ({ data }: { data?: GlobalEcmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.offerToVwap1Day),
    ...rightAligned,
  },
  attributes_pctOfferToOneYear: {
    field: 'attributes_pctOfferToOneYear',
    headerName: '1 Year',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferToOneYear),
    cellRendererFramework: ({ data }: { data?: GlobalEcmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferToOneYear),
    ...rightAligned,
  },
  attributes_pctOfferToPriorQuarter: {
    field: 'attributes_pctOfferToPriorQuarter',
    headerName: 'Prior Quarter',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.pctOfferToPriorQuarter),
    cellRendererFramework: ({ data }: { data?: GlobalEcmOffering }) =>
      percentagePerformanceFormatter(data?.attributes?.pctOfferToPriorQuarter),
    ...rightAligned,
  },
  attributes_totalManagers: {
    field: 'attributes_totalManagers',
    headerName: '# of Managers',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.totalManagers)
        ? numericUtil.formatInteger(data?.attributes?.totalManagers)
        : '-',
    ...rightAligned,
  },
  attributes_totalBookrunners: {
    field: 'attributes_totalBookrunners',
    headerName: '# of Bookrunners',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.totalBookrunners)
        ? numericUtil.formatInteger(data?.attributes?.totalBookrunners)
        : '-',
    ...rightAligned,
  },
  attributes_totalNonBookrunners: {
    field: 'attributes_totalNonBookrunners',
    headerName: '# of Non-Bookrunners',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.totalNonBookrunners)
        ? numericUtil.formatInteger(data?.attributes?.totalNonBookrunners)
        : '-',
    ...rightAligned,
  },
  attributes_totalPctToBookrunners: {
    field: 'attributes_totalPctToBookrunners',
    headerName: '% to Bookrunners',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.totalPctToBookrunners, 1),
    ...rightAligned,
  },
  attributes_totalPctToNonBookrunners: {
    field: 'attributes_totalPctToNonBookrunners',
    headerName: '% to Non-Bookrunners',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.totalPctToNonBookrunners, 1),
    ...rightAligned,
  },
  attributes_totalPctToLeftLead: {
    field: 'attributes_totalPctToLeftLead',
    headerName: '% to Left Lead',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.totalPctToLeftLead, 1),
    ...rightAligned,
  },
  attributes_lockUpPeriod: {
    field: 'attributes_lockUpPeriod',
    headerName: 'Lock-up Period',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      isDefined(data?.attributes?.lockUpPeriod)
        ? numericUtil.formatInteger(data?.attributes?.lockUpPeriod)
        : '-',
    ...rightAligned,
  },
  attributes_lockUpExpirationDate: {
    field: 'attributes_lockUpExpirationDate',
    headerName: 'Lock-up Date',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      formatDate(data?.attributes?.lockUpExpirationDate),
  },
  attributes_lockUpEarlyReleaseDate: {
    field: 'attributes_lockUpEarlyReleaseDate',
    headerName: 'Lock-up Early Release Date',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      formatDate(data?.attributes?.lockUpEarlyReleaseDate),
  },
  attributes_isConditionalLockUp: {
    field: 'attributes_isConditionalLockUp',
    headerName: 'Conditional Lock-up',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isConditionalLockUp),
  },
  attributes_hasMultipleLockUps: {
    field: 'attributes_hasMultipleLockUps',
    headerName: 'Multiple Lock-ups',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.hasMultipleLockUps),
  },
  attributes_totalPreOfferingOwnership: {
    field: 'attributes_totalPreOfferingOwnership',
    headerName: 'Pre Ownership Shares',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      data?.attributes?.totalPreOfferingOwnership
        ? numericUtil.formatInteger(data.attributes.totalPreOfferingOwnership)
        : '-',
    ...rightAligned,
  },
  attributes_totalPctPreOfferingOwnership: {
    field: 'attributes_totalPctPreOfferingOwnership',
    headerName: 'Pre Ownership %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.totalPctPreOfferingOwnership, 2),
    ...rightAligned,
  },
  attributes_totalPostOfferingOwnership: {
    field: 'attributes_totalPostOfferingOwnership',
    headerName: 'Post Ownership Shares',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      data?.attributes?.totalPostOfferingOwnership
        ? numericUtil.formatInteger(data.attributes.totalPostOfferingOwnership)
        : '-',
    ...rightAligned,
  },
  attributes_totalPctPostOfferingOwnership: {
    field: 'attributes_totalPctPostOfferingOwnership',
    headerName: 'Post Ownership %',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      getFormattedPercentageValue(data?.attributes?.totalPctPostOfferingOwnership, 2),
    ...rightAligned,
  },
  attributes_isFirstFollowOn: {
    field: 'attributes_isFirstFollowOn',
    headerName: 'First Follow-on',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isFirstFollowOn),
  },
  attributes_isCarveOut: {
    field: 'attributes_isCarveOut',
    headerName: 'Carve out',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isCarveOut),
  },
  attributes_isCleanUpTrade: {
    field: 'attributes_isCleanUpTrade',
    headerName: 'Clean up trade',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isCleanUpTrade),
  },
  attributes_isConcurrentOffering: {
    field: 'attributes_isConcurrentOffering',
    headerName: 'Concurrent Offering',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isConcurrentOffering),
  },
  attributes_isDualListed: {
    field: 'attributes_isDualListed',
    headerName: 'Additional Listing',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isDualListed),
    headerComponentFramework: () => (
      <HeaderLabelRenderer
        label="Additional Listing"
        tooltip="Execution of an offering and simultaneous new exchange listing from an issuer with prior trade history."
      />
    ),
  },
  attributes_isEgc: {
    field: 'attributes_isEgc',
    headerName: 'EGC',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isEgc),
  },
  attributes_isSyntheticSecondary: {
    field: 'attributes_isSyntheticSecondary',
    headerName: 'Synthetic Secondary',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isSyntheticSecondary),
  },
  attributes_isUpListing: {
    field: 'attributes_isUpListing',
    headerName: 'Up Listing',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isUpListing),
  },
  attributes_useOfProceedsNote: {
    field: 'attributes_useOfProceedsNote',
    headerName: 'Use of proceeds note',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      stringValueFormatter(data?.attributes?.useOfProceedsNote),
  },
  attributes_isCompanyRepurchaseAdditional: {
    field: 'attributes_isCompanyRepurchaseAdditional',
    headerName: 'Company repurchase additional',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isCompanyRepurchaseAdditional),
  },
  attributes_isCompanyRepurchaseIncluded: {
    field: 'attributes_isCompanyRepurchaseIncluded',
    headerName: 'Company repurchase included',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.isCompanyRepurchaseIncluded),
  },
  attributes_isCompanyRepurchase: {
    field: 'attributes_isCompanyRepurchase',
    headerName: 'Company repurchase',
    sortable: false,
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) => {
      if (
        isNil(data?.attributes?.isCompanyRepurchaseIncluded) &&
        isNil(data?.attributes?.isCompanyRepurchaseAdditional)
      ) {
        return '-';
      }
      if (data?.attributes?.isCompanyRepurchaseIncluded) {
        return 'Included';
      }
      if (data?.attributes?.isCompanyRepurchaseAdditional) {
        return 'Additional';
      }
      return 'No';
    },
  },
  issuer_headquarters: {
    field: 'issuer_headquarters',
    headerName: 'Headquarters',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      stringValueFormatter(data?.issuer?.headquarters),
  },
  issuer_naics: {
    field: 'issuer_naics',
    headerName: 'Naics',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      data?.issuer?.naics ? numericUtil.formatInteger(data.issuer.naics) : '-',
    ...rightAligned,
  },
  attributes_hasCornerstoneInvestors: {
    field: 'attributes_hasCornerstoneInvestors',
    headerName: 'Cornerstone Investors (Y/N)',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      booleanValueFormatter(data?.attributes?.hasCornerstoneInvestors),
  },
  attributes_cornerstoneInvestorsTotalCount: {
    field: 'attributes_cornerstoneInvestorsTotalCount',
    headerName: '# of Total Cornerstone Investors',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      data?.attributes?.cornerstoneInvestorsTotalCount
        ? numericUtil.formatInteger(data.attributes.cornerstoneInvestorsTotalCount)
        : '-',
    ...rightAligned,
  },
  cornerstoneInvestmentTypes: {
    field: 'cornerstoneInvestmentTypes',
    headerName: 'Cornerstone Investment Type',
    sortable: false,
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) => {
      const investmentTypes = uniq(
        (data?.cornerstoneInvestments ?? []).map(
          investment => investment.typeDisplayName ?? undefined
        )
      );
      return investmentTypes.length > 0 ? investmentTypes.join(' / ') : '-';
    },
  },
  attributes_cornerstoneTotalAmount: {
    field: 'attributes_cornerstoneTotalAmount',
    headerName: 'Cornerstone Investment Total',
    valueGetter: ({ data }: { data?: GlobalEcmOffering }) =>
      currencyInMillionRenderer({
        value: data?.attributes?.cornerstoneTotalAmount ?? null,
        pricingCurrencyCode: data?.pricingCurrency ?? null,
      }),
    sortable: false,
    ...rightAligned,
  },
};

// define columns with specific properties: ColDef<Offering, any>
export const dataGridColumns: Record<string, ColDef<Offering> & { field: SharedOfferingGridKeys }> =
  {
    issuer_name: {
      field: 'issuer_name',
      headerName: 'Issuer',
      // Column will be always visible
      suppressColumnsToolPanel: true,
      /*
      Since we are using a custom field id with cellRendererFramework
      we need to provide value getter so we can get re-renders working properly
    */
      valueGetter: ({ data }: { data?: Offering }) => data?.id ?? '',
      cellRendererFramework: ({ data, value }: { data?: Offering; value: string }) => {
        return data ? (
          <Link to={routeFactory.offerings.getUrlPath({ id: data.id })}>{data.issuer?.name}</Link>
        ) : (
          value ?? ''
        );
      },
    },
    issuer_sectorDisplayName: {
      field: 'issuer_sectorDisplayName',
      headerName: 'Sector',
      valueGetter: ({ data }: { data?: Offering }) =>
        stringValueFormatter(data?.issuer?.sectorDisplayName),
    },
    issuer_primarySymbol: {
      field: 'issuer_primarySymbol',
      headerName: 'Ticker',
      valueGetter: ({ data }: { data?: Offering }) =>
        stringValueFormatter(data?.issuer?.primarySymbol),
    },
    securityTypeDisplayName: {
      field: 'securityTypeDisplayName',
      headerName: 'Security type',
      valueGetter: ({ data }: { data?: Offering }) => {
        if (data?.securityType === SecurityType.ConvertibleNote) {
          return 'Note';
        }
        return stringValueFormatter(data?.securityTypeDisplayName);
      },
    },
    issuerAuditor: {
      field: 'issuerAuditor',
      headerName: 'Issuer Auditor',
      valueGetter: ({ data }: { data?: Offering }) =>
        getAdviserNameByRole(data?.advisers ?? [], AdviserRole.IssuerAuditor),
      hide: true,
      sortable: false,
    },
    leftLead: {
      field: 'leftLead',
      headerName: 'Left Lead',
      /*
      Since we are using a custom field id with cellRendererFramework
      we need to provide value getter so we can get re-renders working properly
    */
      valueGetter: ({ data }: { data?: Offering }) => data?.attributes?.leftLeadId ?? '',
      cellRendererFramework: ({ data }: { data?: Offering }) => <LeftLeadRenderer data={data} />,
    },
    underwriterCounsel: {
      field: 'underwriterCounsel',
      headerName: 'Underwriter Counsel', // Counsil
      valueGetter: ({ data }: { data?: Offering }) =>
        getAdviserNameByRole(data?.advisers ?? [], AdviserRole.UnderwriterCounsel),
      hide: true,
      sortable: false,
    },
    bookrunners: {
      field: 'bookrunners',
      headerName: 'Bookrunners',
      valueGetter: ({ data }: { data?: Offering }) => {
        const activeBookrunners = getManagerNameByRole(
          data?.managers ?? [],
          OfferingManagerRole.ActiveBookrunner
        );
        const passiveBookrunners = getManagerNameByRole(
          data?.managers ?? [],
          OfferingManagerRole.Bookrunner
        );
        return managerRenderer([...activeBookrunners, ...passiveBookrunners]);
      },
      sortable: false,
    },
    coLeads: {
      field: 'coLeads',
      headerName: 'Co-Leads',
      valueGetter: ({ data }: { data?: Offering }) => {
        const managers = getManagerNameByRole(data?.managers ?? [], OfferingManagerRole.CoLead);
        return managerRenderer(managers);
      },
      hide: true,
      sortable: false,
    },
    coManagers: {
      field: 'coManagers',
      headerName: 'Co-Managers',
      valueGetter: ({ data }: { data?: Offering }) => {
        const managers = getManagerNameByRole(data?.managers ?? [], OfferingManagerRole.CoManager);
        return managerRenderer(managers);
      },
      hide: true,
      sortable: false,
    },
    sellingGroupMembers: {
      field: 'sellingGroupMembers',
      headerName: 'Sales Agents',
      maxWidth: 400,
      valueGetter: ({ data }: { data?: Offering }) => {
        const managers = getManagerNameByRole(
          data?.managers ?? [],
          OfferingManagerRole.SellingGroupMember
        );
        return managerRenderer(managers);
      },
      cellRendererFramework: ({ data }: { data?: Offering }) => {
        const managers = getManagerNameByRole(
          data?.managers ?? [],
          OfferingManagerRole.SellingGroupMember
        );
        return <ManagersRenderer managers={managers} />;
      },
      sortable: false,
    },
    publicFilingDate: {
      field: 'publicFilingDate',
      headerName: 'Public Filing Date',
      ...dateColumn,
    },
    launchDate: {
      field: 'launchDate',
      headerName: 'Launch Date',
      ...dateColumn,
    },
    marketTimingDisplayName: {
      field: 'marketTimingDisplayName',
      headerName: 'Timing of Launch',
      ...stringValueColumn,
    },
    issuer_cik: {
      field: 'issuer_cik',
      headerName: 'CIK',
      valueGetter: ({ data }: { data?: Offering }) => stringValueFormatter(data?.issuer?.cik),
      ...rightAligned,
    },
    statusDisplayName: {
      field: 'statusDisplayName',
      headerName: 'Status',
      ...stringValueColumn,
    },
    hasForwardAgreement: {
      field: 'hasForwardAgreement',
      headerName: 'Forward Sale',
      ...booleanColumn,
    },
    firstTradeDate: {
      field: 'firstTradeDate',
      headerName: 'First Trade Date',
      ...dateColumn,
    },
    settlementDate: {
      field: 'settlementDate',
      headerName: 'Settlement Date',
      ...dateColumn,
    },
    attributes_primaryShareholderName: {
      field: 'attributes_primaryShareholderName',
      headerName: 'Shareholder',
      sortable: false,
      /*
      Since we are using a custom field id with cellRendererFramework
      we need to provide value getter so we can get re-renders working properly
    */
      valueGetter: ({ data }: { data?: Offering }) =>
        data?.attributes?.primaryShareholderFirmId ?? '',
      cellRendererFramework: ({ data }: { data?: Offering }) => <ShareholderRenderer data={data} />,
    },
    cornerstoneInvestorList: {
      field: 'cornerstoneInvestorList',
      headerName: 'Cornerstone Investors',
      sortable: false,
      maxWidth: 300,
      /*
      Since we are using a custom field id with cellRendererFramework
      we need to provide value getter so we can get re-renders working properly
    */
      valueGetter: ({ data }: { data?: Offering }) =>
        data?.attributes?.cornerstoneTotalAmount
          ? `${data?.attributes?.cornerstoneTotalAmount}`
          : '',
      cellRendererFramework: ({ data }: { data?: Offering }) => (
        <CornerstoneInvestorsRenderer offering={data} />
      ),
    },
    ...atmColumns,
    ...convertColumns,
    ...globalEcmColumns,
  };

/*
  List of overriding props for INTL columns
*/
export const intlOverridingProps = {
  [dataGridColumns.attributes_latestGrossProceedsBaseUsd.field!]: {
    headerName: 'Gross Proceeds Base $',
  },
  [dataGridColumns.attributes_latestGrossProceedsTotalUsd.field!]: {
    headerName: 'Gross Proceeds Total $',
  },
  [dataGridColumns.attributes_marketCapAtPricingUsd.field!]: {
    headerName: 'Post-Offering Mkt. Cap $',
  },
  [dataGridColumns.attributes_priceUsd.field!]: {
    headerName: 'Offer Price $',
  },
  [dataGridColumns.attributes_lastTradeBeforeFilingUsd.field!]: {
    headerName: 'Last Trade Before Filing $',
  },
  [dataGridColumns.attributes_lastTradeBeforeOfferUsd.field!]: {
    headerName: 'Last Trade Before Offer $',
  },
  [dataGridColumns.attributes_initialIpoRangeUsd.field!]: {
    headerName: 'Initial IPO Range $',
  },
  [dataGridColumns.attributes_reOfferHighUsd.field!]: {
    headerName: 'Re Offer High $',
  },
  [dataGridColumns.attributes_reOfferLowUsd.field!]: {
    headerName: 'Re Offer Low $',
  },
  [dataGridColumns.attributes_initialGrossProceedsBaseUsd.field!]: {
    headerName: 'Gross Proceeds Base $ (Initial)',
  },
  [dataGridColumns.attributes_revisedGrossProceedsBaseUsd.field!]: {
    headerName: 'Gross Proceeds Base $ (Revised)',
  },
  [dataGridColumns.attributes_marketCapPreOfferingUsd.field!]: {
    headerName: 'Pre-Offering Mkt. Cap $',
  },
  [dataGridColumns.attributes_estimatedFeeUsd.field!]: {
    headerName: 'Estimated Fee $',
  },
  [dataGridColumns.attributes_revisedIpoRangeUsd.field!]: {
    headerName: 'Revised IPO Range $',
  },
  [dataGridColumns.attributes_initialIndicativeGrossProceedsUsd.field!]: {
    headerName: 'Initial Registration Value $',
  },
};

export const localCurrencyColumns = [
  dataGridColumns.exchangeMicDisplayName,
  dataGridColumns.exchangeRegionDisplayName,
  dataGridColumns.exchangeCountryDisplayName,
  dataGridColumns.pricingCurrencyDisplayName,
  dataGridColumns.attributes_latestGrossProceedsBase,
  dataGridColumns.attributes_latestGrossProceedsTotal,
  dataGridColumns.attributes_marketCapAtPricing,
  dataGridColumns.price,
  dataGridColumns.attributes_lastTradeBeforeFiling,
  dataGridColumns.attributes_lastTradeBeforeOffer,
  dataGridColumns.attributes_initialIpoRange,
  dataGridColumns.attributes_reOfferHigh,
  dataGridColumns.attributes_reOfferLow,
  dataGridColumns.attributes_initialGrossProceedsBase,
  dataGridColumns.attributes_revisedGrossProceedsBase,
];
export const alwaysVisibleColumns = ['issuer_name'];

export const getSortingModel = (colId: string, sort: SortEnumType): NestedSortInput => {
  if (getColumnById(colId)?.sortable === false) {
    return {};
  }
  return sortModelMappings[colId]
    ? sortModelMappings[colId](sort)
    : {
        [colId]: sort,
      };
};

// define style and renderer for pinned row that will show summary information (min, max, median, etc.)
export const getColumnsWithSummaryInfo = (
  columns: ColDef<Offering, any>[],
  aggregations?: OfferingAggregations
): ColDef<Offering, any>[] => {
  const defaultCellStyle: CreateCellStyleFn<Offering, any> = () => ({ border: 0 });
  return (
    columns.map(column => {
      const fieldName = aggregationMapping[column.field ?? '']?.fieldName;
      const formatter = aggregationMapping[column.field ?? '']?.formatter;
      if (aggregations && column.field && fieldName && aggregations[fieldName]) {
        return {
          ...column,
          cellStyle: params =>
            params.node?.isRowPinned()
              ? {
                  ...defaultCellStyle(params),
                  display: 'flex',
                  justifyContent: 'flex-end',
                  lineHeight: '13px',
                }
              : defaultCellStyle(params),
          pinnedRowCellRendererFramework: () => (
            <SummaryHeader aggregations={aggregations[fieldName]} formatter={formatter} />
          ),
        };
      }
      return {
        ...column,
        cellStyle: defaultCellStyle,
        pinnedRowCellRendererFramework: () => null,
      };
    }) ?? []
  );
};

export const getInitialVisibleColumns = (
  columns: ColDef<Offering, any>[],
  visibleColumns: ColDef<Offering, any>[] = [],
  localCurrVisibleColumns?: ColDef<Offering, any>[],
  showInternational: boolean = false
): ColDef<Offering, any>[] => {
  const allVisibleColumns =
    showInternational && localCurrVisibleColumns
      ? [...visibleColumns, ...localCurrVisibleColumns]
      : visibleColumns;
  return columns?.map(column => {
    if (column.field && allVisibleColumns.some(c => c.field === column.field)) {
      return {
        ...column,
        hide: false,
      };
    }
    return {
      ...column,
      hide: true,
    };
  });
};

export const overrideIntlProps = (column: ColDef<Offering, any>): ColDef<Offering, any> =>
  Object.keys(intlOverridingProps).includes(column.field!)
    ? {
        ...column,
        ...intlOverridingProps[column.field!],
      }
    : column;
export const localCurrencyColumnsFilter = (column: ColDef<Offering, any>): boolean => {
  return !localCurrencyColumns.some(localCol => localCol.field === column.field);
};

export const getColumnsDef = (
  orderedColumns: ColDef<Offering, any>[],
  catergorizedColumns: CategorizedColumn,
  visibleColumns?: ColDef<Offering, any>[],
  localCurrVisibleColumns?: ColDef<Offering, any>[],
  showInternational: boolean = false
): {
  columnsDef: ColDef<Offering, any>[];
  categorizedColumns: CategorizedColumn;
} => {
  const columnSelection = cloneDeep(catergorizedColumns);
  for (const [category, columns] of Object.entries(catergorizedColumns)) {
    columnSelection[category] = showInternational
      ? columns.map(overrideIntlProps)
      : columns.filter(localCurrencyColumnsFilter);
  }
  const initialVisibleColumns = getInitialVisibleColumns(
    orderedColumns,
    visibleColumns,
    localCurrVisibleColumns,
    showInternational
  );
  return {
    columnsDef: showInternational
      ? initialVisibleColumns.map(overrideIntlProps)
      : initialVisibleColumns,
    categorizedColumns: columnSelection,
  };
};

export const additionalOptions = {
  initialTerm_couponTalkPercentageLow: {
    numberPrecision: 5,
  },
  initialTerm_couponTalkPercentageHigh: {
    numberPrecision: 5,
  },
  latestRevisedTerm_couponTalkPercentageLow: {
    numberPrecision: 5,
  },
  latestRevisedTerm_couponTalkPercentageHigh: {
    numberPrecision: 5,
  },
  initialTerm_premiumTalkLowPercentage: {
    numberPrecision: 2,
  },
  initialTerm_premiumTalkHighPercentage: {
    numberPrecision: 2,
  },
  latestRevisedTerm_premiumTalkLowPercentage: {
    numberPrecision: 2,
  },
  latestRevisedTerm_premiumTalkHighPercentage: {
    numberPrecision: 2,
  },
  finalTerm_couponPercentage: {
    numberPrecision: 5,
  },
  finalTerm_premiumPercentage: {
    numberPrecision: 2,
  },
  finalTerm_pctOfferPrice: {
    numberPrecision: 2,
  },
  convertibleAttributes_callPrice: {
    numberPrecision: 2,
  },
  attributes_pctGrossSpread: {
    numberPrecision: 2,
  },
  finalTerm_effectiveConversionPremium: {
    numberPrecision: 2,
  },
  atmAttributes_pctGrossSpread: {
    numberPrecision: 2,
  },
};

// Recursively extract field name into Json object, preserving gql schema structure
export const fieldNameToJson = (acc, input, index) => {
  if (index >= input.length) {
    return '';
  }
  const item = input[index];
  acc[item] = fieldNameToJson(acc[item] ?? {}, input, index + 1);
  return acc;
};

// We need to query these fields for initial/revised/final terms calculated fields to work
export const defaultBaseGqlFields = {
  initialTermId: '',
  latestRevisedTermId: '',
  finalTermId: '',
  terms: {
    id: '',
    aggregatePrincipalAmount: '',
    aggregatePrincipalInclOverallotmentExercised: '',
    principalAmountOverallotmentAuthorized: '',
    principalAmountPerNote: '',
    couponTalkPercentageLow: '',
    couponTalkPercentageHigh: '',
    premiumTalkLowPercentage: '',
    premiumTalkHighPercentage: '',
    conversionPrice: '',
    conversionRatio: '',
    conversionReferencePrice: '',
    principalAmountOverallotmentExercised: '',
    effectiveConversionPremium: '',
    effectiveConversionPrice: '',
    versusTalkDisplayName: '',
    premiumPercentage: '',
    couponPercentage: '',
    pctOfferPrice: '',
    hasZeroCoupon: '',
  },
};

export const excludeFromGql = [
  dataGridColumns.leftLead.field,
  dataGridColumns.bookrunners.field,
  dataGridColumns.coLeads.field,
  dataGridColumns.coManagers.field,
  dataGridColumns.trustee.field,
  dataGridColumns.issuerCounsel.field,
  dataGridColumns.underwriterCounsel.field,
  dataGridColumns.sellingGroupMembers.field,
  dataGridColumns.issuerAuditor.field,
  dataGridColumns.convertibleAttributes_putNote.field,
  dataGridColumns.attributes_isCompanyRepurchase.field,
  dataGridColumns.cornerstoneInvestorList.field,
  dataGridColumns.cornerstoneInvestmentTypes.field,
] as string[];

export const rangeColumns = {
  initialTerm_couponTalkPercentageRange: [
    {
      colId: 'initialTerm_couponTalkPercentageLow',
      colName: 'Coupon Talk (Initial) - Low',
    },
    {
      colId: 'initialTerm_couponTalkPercentageHigh',
      colName: 'Coupon Talk (Initial) - High',
    },
  ],
  initialTerm_premiumTalkPercentageRange: [
    {
      colId: 'initialTerm_premiumTalkLowPercentage',
      colName: 'Premium Talk (Initial) - Low',
    },
    {
      colId: 'initialTerm_premiumTalkHighPercentage',
      colName: 'Premium Talk (Initial) - High',
    },
  ],
  latestRevisedTerm_couponTalkPercentageRange: [
    {
      colId: 'latestRevisedTerm_couponTalkPercentageLow',
      colName: 'Coupon Talk (Revised) - Low',
    },
    {
      colId: 'latestRevisedTerm_couponTalkPercentageHigh',
      colName: 'Coupon Talk (Revised) - High',
    },
  ],
  latestRevisedTerm_premiumTalkPercentageRange: [
    {
      colId: 'latestRevisedTerm_premiumTalkLowPercentage',
      colName: 'Premium Talk (Revised) - Low',
    },
    {
      colId: 'latestRevisedTerm_premiumTalkHighPercentage',
      colName: 'Premium Talk (Revised) - High',
    },
  ],
  attributes_initialIpoRange: [
    {
      colId: 'attributes_initialIpoRangeLow',
      colName: 'Initial IPO Range - Low',
    },
    {
      colId: 'attributes_initialIpoRangeHigh',
      colName: 'Initial IPO Range - High',
    },
  ],
  attributes_initialIpoRangeUsd: [
    {
      colId: 'attributes_initialIpoRangeLowUsd',
      colName: 'Initial IPO Range $ - Low',
    },
    {
      colId: 'attributes_initialIpoRangeHighUsd',
      colName: 'Initial IPO Range $ - High',
    },
  ],
  attributes_revisedIpoRangeUsd: [
    {
      colId: 'attributes_revisedIpoRangeLowUsd',
      colName: 'Revised IPO Range $ - Low',
    },
    {
      colId: 'attributes_revisedIpoRangeHighUsd',
      colName: 'Revised IPO Range $ - High',
    },
  ],
};

type GetExcelDownloadArgs = {
  selectedColumns?: Column[];
  gqlFilterInput?: OfferingFilterInput;
  sortModel?: {
    colId?: string;
    sort?: string | null;
  }[];
  selectedOptions?: string[];
  defaultSortModel?: NestedSortInput;
  baseGqlFields?: Object;
};

// Turn selected fields, filter, and sort model into excel download args
export const getExcelDownloadArgs = ({
  selectedColumns = [],
  gqlFilterInput,
  sortModel = [],
  selectedOptions = [],
  defaultSortModel = {},
  baseGqlFields = defaultBaseGqlFields,
}: GetExcelDownloadArgs): datalabApi.ConvertibleOfferingRequestDto => {
  let index = 0;
  const columnOptions = selectedColumns.reduce((acc, item) => {
    const colDef = item.getColDef();
    const colId = item.getColId();
    // add multiple columns to excel if it is a (low - high) range
    if (rangeColumns[colId]) {
      rangeColumns[colId].forEach(rangeItem => {
        const rangeColId = rangeItem.colId.replaceAll('_', '.');
        acc[rangeColId] = {
          columnName: rangeItem.colName,
          displayOrder: index++,
          ...additionalOptions[rangeItem.colId],
        };
      });
    } else {
      acc[colId.replaceAll('_', '.')] = {
        columnName: colDef.headerName,
        displayOrder: index++,
        ...additionalOptions[colId],
      };
    }
    return acc;
  }, {});

  // Recursively turn selected fields into json object, following gql schema structure
  const selectionJson = Object.keys(columnOptions).reduce((acc, colId) => {
    const fieldId = colId.replaceAll('.', '_');
    return excludeFromGql.includes(fieldId) ? acc : fieldNameToJson(acc, colId.split('.'), 0);
  }, baseGqlFields);

  // Stringify json object and replace non-gql characters
  const selectionString = JSON.stringify(selectionJson)
    .replaceAll(/"|'|:/gi, '') // remove single ('), double quotes ("), and colon (:)
    .replaceAll(/,/gi, ' '); // replace comma (,) with space
  const selection = selectionString
    .substring(1, selectionString.length - 1) // remove first and last braces ({})
    .replaceAll(/{|}/gi, ' $& '); // add space around braces ({})

  const downloadArg = {
    selection,
    columnOptions,
    arguments: {
      order: [
        sortModel.length > 0 && sortModel[0].colId && sortModel[0].sort
          ? {
              ...getSortingModel(
                sortModel[0].colId,
                sortModel[0].sort.toUpperCase() as SortEnumType
              ),
            }
          : defaultSortModel,
      ],
      where: gqlFilterInput ?? {},
    },
    includeUnderwritersWorksheet: selectedOptions.includes('includeUnderwritersWorksheet'),
  };
  return downloadArg;
};
