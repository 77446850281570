import React from 'react';

import { Group, Option } from '../../..';
import { PopoverVariant } from '../../overlays/popover/Popover';
import DropdownContainer from '../dropdown-container/DropdownContainer';
import DropdownSelectCategorizedContent from './DropdownSelectCategorizedContent';
import DropdownSelectContent from './DropdownSelectContent';
import { DropdownSelectItem } from './DropdownSelectItem';

export enum DropdownSelectVariant {
  DARK = PopoverVariant.DARK,
  LIGHT = PopoverVariant.LIGHT,
}

export type Props<TValue> = {
  label: React.ReactNode;
  items: DropdownSelectItem<TValue>[];
  categorizedItems?: (Group<TValue> | Option<TValue>)[];
  groupedItems?: Group<TValue>[];
  onChange: (values: TValue[]) => void;
  values?: TValue[];
  multiple?: boolean;
  searchable?: boolean;
  popoverVariant?: keyof typeof DropdownSelectVariant;
};

const DropdownSelect: <TValue = string>(p: Props<TValue>) => React.ReactElement<Props<TValue>> = ({
  label,
  items,
  categorizedItems,
  groupedItems,
  values = [],
  multiple,
  searchable,
  onChange,
  popoverVariant = 'DARK',
}) => {
  return (
    <DropdownContainer
      content={({ setVisibility }) =>
        !!categorizedItems ? (
          <DropdownSelectCategorizedContent
            searchable={searchable}
            onChange={onChange}
            categorizedItems={categorizedItems}
            groupedItems={groupedItems}
            values={values}
          />
        ) : (
          <DropdownSelectContent
            items={items}
            values={values}
            searchable={searchable}
            multiple={multiple}
            onChange={onChange}
            setVisible={setVisibility}
          />
        )
      }
      popoverVariant={popoverVariant}
      autoAdjustOverflow={!searchable}
    >
      {label}
    </DropdownContainer>
  );
};

export default DropdownSelect;
