import { ErrorApiResponse, SuccessApiResponse } from '@cmg/api';
import { apiUtil, ColDef, DataGrid, IGetRowsParams, ToastManager } from '@cmg/common';
import { GridReadyEvent } from 'ag-grid-community';
import { saveAs } from 'file-saver';
import React, { useCallback } from 'react';

import { CategorizedColumn } from '../../../../graphql/types';
import { getColumnsWithSummaryInfo } from './OfferingsReportTable.model';
import { Offering, OfferingAggregations } from './types';

export type Props = {
  getRows: (params: IGetRowsParams) => void;
  numberOfRowsFetched?: number;
  totalRows?: number;
  isLoading?: boolean;
  gridRef?: React.MutableRefObject<GridReadyEvent | undefined>;
  aggregations?: OfferingAggregations;
  categorizedColumns?: CategorizedColumn;
  groupedColumns?: CategorizedColumn;
  alwaysVisibleColumns?: string[];
  columnsDef?: ColDef<Offering, any>[];
  title?: string;
  downloadOptions?: {
    value: string;
    label: string;
  }[];
  onDownload?: (selectedOptions: string[]) => Promise<ErrorApiResponse | SuccessApiResponse<Blob>>;
  defaultVisibleColumns?: ColDef<Offering, any>[];
};

const OfferingsReportTable: React.FC<Props> = ({
  getRows,
  isLoading,
  numberOfRowsFetched,
  totalRows,
  gridRef,
  aggregations,
  categorizedColumns,
  groupedColumns,
  alwaysVisibleColumns = [],
  columnsDef = [],
  title = '',
  downloadOptions = [],
  onDownload,
}) => {
  const columns = React.useMemo(
    () => getColumnsWithSummaryInfo(columnsDef, aggregations),
    [aggregations, columnsDef]
  );

  const autoSizeColumns = useCallback(() => {
    // Since we are using custom cellRenderFrameworks ag grid cannot calculate since they render seperatly
    // we need to give it some time to properly calculate the fit content
    setTimeout(() => {
      const columns = gridRef?.current?.columnApi?.getAllColumns() ?? [];
      const colIds = columns.filter(c => !c.getMaxWidth()).map(c => c.getColId());
      gridRef?.current?.columnApi?.autoSizeColumns(colIds, false);
    }, 250);
  }, [gridRef]);

  const onColumnVisible = React.useCallback(() => {
    alwaysVisibleColumns.forEach(columnId => {
      gridRef?.current?.columnApi?.setColumnVisible(columnId, true);
    });
    autoSizeColumns();
  }, [alwaysVisibleColumns, autoSizeColumns, gridRef]);

  const handleOnDownload = React.useCallback(
    async (selectedOptions: string[]) => {
      if (!onDownload) {
        return;
      }
      try {
        const respond = await onDownload(selectedOptions);
        if (respond.ok) {
          saveAs(
            respond.data,
            apiUtil.getFilenameFromContentDisposition(
              respond.headers['content-disposition'] ?? '',
              'report-download.xlsx'
            )
          );
          ToastManager.success('Download successfully completed.');
        }
      } catch (err) {
        ToastManager.error(
          'An error has occurred while trying to download your data. Please try again later.'
        );
      }
    },
    [onDownload]
  );
  return (
    <DataGrid
      onGridReady={event => {
        if (gridRef) {
          gridRef.current = event;
        }
      }}
      onModelUpdated={() => autoSizeColumns()}
      columns={columns}
      categorizedColumns={categorizedColumns}
      groupedColumns={groupedColumns}
      onColumnVisible={onColumnVisible}
      resizeBy="grid"
      domLayout="normal"
      gridOptions={{
        // We have a fixed ammount of columns so it is acceptable to supress virtualization
        suppressColumnVirtualisation: true,
        alwaysShowHorizontalScroll: true,
      }}
      extended={{
        fillViewport: true,
        hidePagination: true,
        hideColumnResize: true,
        withMargin: false,
        title,
        downloadOptions,
        onDownload: onDownload ? handleOnDownload : undefined,
        downloadTitle: 'Download Offering Terms',
      }}
      resizeStrategy="fit-content"
      loading={isLoading}
      datasource={{
        // to behave as infinite scroll, ag grid requires getRows definition
        getRows,
      }}
      infiniteScroll
      numberOfRowsFetched={numberOfRowsFetched}
      totalRows={totalRows}
      {...(aggregations && {
        // to show pinned row, DataGrid requires getRowsPinnedToTop and rows to be defined
        // passing in totalRows to trigger pinned row re-rendering when we change the filter
        getRowsPinnedToTop: () => [totalRows],
        rows: [{}],
      })}
    />
  );
};

export default OfferingsReportTable;
