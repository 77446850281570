import React from 'react';

import { Group, Option } from '../../form/select/types';
import CheckboxTree from '../../lists/checkbox-tree/CheckboxTree';
import { GroupToggler } from '../grouped-toggler/GroupToggler';
import { SGroupCheckboxList, StyledFilterInput, StyledInputIcon } from './DropdownSelect.styles';

// Exported for testing only
export type Props<TValue> = {
  categorizedItems: (Group<TValue> | Option<TValue>)[];
  groupedItems?: Group<TValue>[];
  onChange: (values: TValue[]) => void;
  values?: TValue[];
  searchable?: boolean;
};

const DropdownSelectCategorizedContent: <TValue = string>(
  p: Props<TValue>
) => React.ReactElement<Props<TValue>> = ({
  categorizedItems,
  groupedItems,
  onChange,
  values,
  searchable,
}) => {
  const renderGroupedToggler = React.useCallback(() => {
    return groupedItems?.length ? (
      <SGroupCheckboxList>
        {groupedItems.map(group => (
          <GroupToggler key={group.key} group={group} values={values ?? []} onChange={onChange} />
        ))}
      </SGroupCheckboxList>
    ) : null;
  }, [groupedItems, onChange, values]);

  return (
    <CheckboxTree
      maxHeight={searchable ? 342 : 380}
      searchable={searchable}
      onChange={onChange}
      renderSearchInput={({ onChange: onSearch, value }) => (
        <StyledFilterInput
          value={value || ''}
          onChange={onSearch}
          placeholder="Filter"
          prefix={<StyledInputIcon name="filter" />}
          suffix={
            <span onClick={() => onSearch('')}>
              <StyledInputIcon name="times-circle" />
            </span>
          }
        />
      )}
      options={categorizedItems}
      renderHeader={renderGroupedToggler}
      values={values}
    />
  );
};

export default DropdownSelectCategorizedContent;
