import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import React from 'react';

import { Group } from '../../form/select/types';
import { SGroupLabelWrapper, StyledCheckbox } from './GroupToggler.styles';

export type Props<TValue> = {
  disabled?: TValue[];
  values: TValue[];
  group: Group<TValue>;
  onChange: (values: TValue[]) => void;
};
export const GroupToggler: <TValue = string>(
  p: Props<TValue>
) => React.ReactElement<Props<TValue>> = ({ disabled = [], values, group, onChange }) => {
  const handleGroupSelectionChange = (checked: boolean) => {
    const groupOptionValues = group.options.map(option => option.value);
    const groupSelection = values.filter(
      value => groupOptionValues.includes(value) && !disabled.includes(value)
    );

    let nextValues;

    if (checked || !groupSelection.length) {
      nextValues = [...values, ...groupOptionValues];
    } else {
      nextValues = values.filter(value => !groupSelection.includes(value));
    }

    // Remove possible duplicated values
    nextValues = uniqWith(nextValues, isEqual);
    onChange(nextValues);
  };
  const allSelected = React.useMemo(
    () => group.options.every(option => values.includes(option.value)),
    [group.options, values]
  );
  const intermediate = React.useMemo(() => {
    const someSelected = group.options.some(option => values.includes(option.value));
    return someSelected && !allSelected;
  }, [allSelected, group.options, values]);

  return (
    <SGroupLabelWrapper>
      <StyledCheckbox
        key={group.options.map(({ value }) => value).join(',')}
        indeterminate={intermediate}
        value={allSelected}
        onChange={handleGroupSelectionChange}
      />
      {group.label}
    </SGroupLabelWrapper>
  );
};
