/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import {
  Atm_Attributes_FieldsFragmentDoc,
  Atm_Selldown_FieldsFragmentDoc,
  Atm_Selldown_Term_FieldsFragmentDoc,
} from '../../../../../common/graphql/__generated__/commonFragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingProfile_Terms_PartFragment = {
  __typename?: 'Term';
  createdAt: string;
  aggregatePrincipalAmount?: number | null;
  principalAmountOverallotmentAuthorized?: number | null;
  principalAmountOverallotmentExercised?: number | null;
  aggregatePrincipalInclOverallotmentExercised?: number | null;
  couponTalkPercentageLow?: number | null;
  couponTalkPercentageHigh?: number | null;
  premiumTalkLowPercentage?: number | null;
  premiumTalkHighPercentage?: number | null;
  offerPrice?: number | null;
  principalAmountPerNote?: number | null;
  conversionReferencePrice?: number | null;
  conversionPrice?: number | null;
  effectiveConversionPrice?: number | null;
  effectiveConversionPremium?: number | null;
  versusTalkDisplayName?: string | null;
  pctOfferPrice?: number | null;
  conversionRatio?: number | null;
  premiumPercentage?: number | null;
  couponPercentage?: number | null;
  sizeInSecuritiesBase?: number | null;
  overAllotmentAuthorized?: number | null;
  overAllotmentAuthorizedPrimary?: number | null;
  overAllotmentAuthorizedSecondary?: number | null;
  sizeInSecuritiesBasePrimary?: number | null;
  sizeInSecuritiesBaseSecondary?: number | null;
  ipoRangeLow?: number | null;
  ipoRangeHigh?: number | null;
  revisedDate?: string | null;
  grossProceedsBase?: number | null;
};

export type OfferingProfile_OfferingShareholder_PartFragment = {
  __typename?: 'OfferingShareholder';
  name?: string | null;
  shareholderTypeDisplayName?: string | null;
  preOfferingOwnership?: number | null;
  pctPreOfferingOwnership?: number | null;
  postOfferingOwnership?: number | null;
  pctPostOfferingOwnership?: number | null;
  postOfferingOwnershipIncludingShoe?: number | null;
  pctPostOfferingOwnershipIncludingShoe?: number | null;
  isCarveOutParent?: boolean | null;
};

export type OfferingProfile_Document_PartFragment = {
  __typename?: 'Document';
  type: Types.DocumentType;
  typeDisplayName?: string | null;
  link: any;
  name?: string | null;
  publicationDate?: string | null;
};

export type OfferingProfile_CornerstoneInvestment_PartFragment = {
  __typename?: 'CornerstoneInvestment';
  individualAmount?: number | null;
  totalInvestors: number;
  type: Types.CornerstoneInvestmentType;
  typeDisplayName?: string | null;
  investors: Array<{
    __typename?: 'CornerstoneInvestor';
    cornerstoneInvestmentId: string;
    isExistingCornerstoneInvestor: boolean;
    shareholder: {
      __typename?: 'Shareholder';
      name?: string | null;
      shareholderTypeDisplayName?: string | null;
    };
    cornerstoneInvestment: {
      __typename?: 'CornerstoneInvestment';
      individualAmount?: number | null;
      totalInvestors: number;
      type: Types.CornerstoneInvestmentType;
      typeDisplayName?: string | null;
    };
  }>;
};

export type OfferingProfile_ListQueryVariables = Types.Exact<{
  offeringId: Types.Scalars['ID'];
  isConvert: Types.Scalars['Boolean'];
  isAtm: Types.Scalars['Boolean'];
  hasSellDownPermission: Types.Scalars['Boolean'];
  hasMySectorsPermission: Types.Scalars['Boolean'];
}>;

export type OfferingProfile_ListQuery = {
  __typename?: 'Query';
  offeringById?: {
    __typename?: 'Offering';
    type: Types.OfferingType;
    isExecutingOnPlatform?: boolean | null;
    exchange?: string | null;
    exchangeMic?: Types.OfferingSetupExchangeMic | null;
    exchangeMicDisplayName?: string | null;
    exchangeRegion?: Types.Region | null;
    exchangeCountry?: Types.Country | null;
    symbol?: string | null;
    typeDisplayName?: string | null;
    status: Types.OfferingStatus;
    statusDisplayName?: string | null;
    pricingCurrency?: Types.Currency | null;
    exchangeRegionDisplayName?: string | null;
    exchangeCountryDisplayName?: string | null;
    marketTimingDisplayName?: string | null;
    securityType?: Types.SecurityType | null;
    securityTypeDisplayName?: string | null;
    isRule144A?: boolean | null;
    publicFilingDate?: string | null;
    lastTradeBeforeFiling?: number | null;
    hasForwardAgreement?: boolean | null;
    terminatedDate?: string | null;
    launchDate?: string | null;
    pricingDate?: string | null;
    firstTradeDate?: string | null;
    settlementDate?: string | null;
    maturityDate?: string | null;
    withdrawnDate?: string | null;
    postponedDate?: string | null;
    price?: number | null;
    initialTermId?: string | null;
    latestRevisedTermId?: string | null;
    finalTermId?: string | null;
    useOfProceedsDisplayNames?: Array<string | null> | null;
    issuer?: {
      __typename?: 'Issuer';
      id?: string | null;
      name?: string | null;
      sectorDisplayName?: string | null;
      subSectorDisplayName?: string | null;
      entityTypes?: Array<Types.EntityType> | null;
      primarySymbol?: string | null;
      customSectors?: {
        __typename?: 'CustomSectorIssuer';
        customSector?: { __typename?: 'CustomSector'; name?: string | null } | null;
        customSubSector?: { __typename?: 'CustomSector'; name?: string | null } | null;
      } | null;
    } | null;
    convertibleAttributes?: {
      __typename?: 'ConvertibleAttributes';
      isCallable?: boolean | null;
      callableDate?: string | null;
      callPrice?: number | null;
      tenor?: number | null;
      rankDisplayName?: string | null;
      hasProvisionalCall?: boolean | null;
      isPuttable?: boolean | null;
      hasContingentConversion?: boolean | null;
      hasContingentPayment?: boolean | null;
      hasDividendProtection?: boolean | null;
      isHedging?: boolean | null;
      isPerpetual?: boolean | null;
      securityNote?: string | null;
      dividendProtectionNote?: string | null;
      putNote?: string | null;
      provisionalCallNote?: string | null;
      contingentConversionNote?: string | null;
      contingentPaymentNote?: string | null;
      hedgingNote?: string | null;
      changeOfControl?: string | null;
      underlyingSymbol?: string | null;
    } | null;
    attributes?: {
      __typename?: 'OfferingAttributes';
      isSpac: boolean;
      pricingCurrency?: Types.Currency | null;
      sizeUsd?: number | null;
      latestSizeInSecuritiesTotal?: number | null;
      marketCapPreOffering?: number | null;
      pctMarketCapPreOffering?: number | null;
      marketCapAtPricing?: number | null;
      pctMarketCapAtPricing?: number | null;
      preOfferingAdtv?: number | null;
      adtvRangeLowDate?: string | null;
      adtvRangeHighDate?: string | null;
      initialGrossProceedsBase?: number | null;
      revisedGrossProceedsBase?: number | null;
      latestGrossProceedsBase?: number | null;
      latestGrossProceedsTotal?: number | null;
      latestSizeInSecuritiesBase?: number | null;
      latestSizeInSecuritiesTotalPrimary?: number | null;
      latestSizeInSecuritiesTotalSecondary?: number | null;
      priceUsd?: number | null;
      leftLeadName?: string | null;
      stabilizationAgentName?: string | null;
      settlementAgentName?: string | null;
      pctGrossSpread?: number | null;
      grossSpreadTotalUsd?: number | null;
      grossSpreadTotal?: number | null;
      sellingConcessionUsd?: number | null;
      managementFeeUsd?: number | null;
      underwritingFeeUsd?: number | null;
      lockUpPeriod?: number | null;
      lockUpExpirationDate?: string | null;
      lockUpEarlyReleaseDate?: string | null;
      isConditionalLockUp?: boolean | null;
      hasMultipleLockUps?: boolean | null;
      lastTradeBeforeFiling?: number | null;
      firstDayVolume?: number | null;
      firstDayTurnover?: number | null;
      offerToVwap1Day?: number | null;
      pctOfferToOpen?: number | null;
      pctOfferTo1Day?: number | null;
      pctOfferTo3Day?: number | null;
      pctOfferTo7Day?: number | null;
      pctOfferTo14Day?: number | null;
      pctOfferTo30Day?: number | null;
      pctOfferTo90Day?: number | null;
      pctOfferTo180Day?: number | null;
      pctOfferToOneYear?: number | null;
      pctOfferToCurrent?: number | null;
      pctOfferToPriorQuarter?: number | null;
      day1VwapPrice?: number | null;
      openingSharePrice?: number | null;
      currentSharePrice?: number | null;
      day1SharePrice?: number | null;
      day3SharePrice?: number | null;
      day7SharePrice?: number | null;
      day14SharePrice?: number | null;
      day30SharePrice?: number | null;
      day90SharePrice?: number | null;
      day180SharePrice?: number | null;
      oneYearSharePrice?: number | null;
      priorQuarterSharePrice?: number | null;
      isDualListed?: boolean | null;
      isFdicRegistered?: boolean | null;
      isSecRegistered?: boolean | null;
      latestPctSecondaryShares?: number | null;
      confidentialFilingDate?: string | null;
      isAccelerated?: boolean | null;
      isRegS?: boolean | null;
      isRule144A?: boolean | null;
      totalManagers?: number | null;
      totalBookrunners?: number | null;
      totalNonBookrunners?: number | null;
      estimatedFeeUsd?: number | null;
      netPrice?: number | null;
      pctAllInCost?: number | null;
      allInCost?: number | null;
      totalPctToActiveManagers?: number | null;
      totalPctToCoManagers?: number | null;
      totalPctToBookrunners?: number | null;
      totalPctToNonBookrunners?: number | null;
      totalPctToLeftLead?: number | null;
      preOfferingShares?: number | null;
      latestPostOfferingShares?: number | null;
      splitAdjustedOfferPrice?: number | null;
      pctVsMidpoint?: number | null;
      pctChangeInSize?: number | null;
      priceVsRange?: Types.PriceVsRange | null;
      priceVsRangeDisplayName?: string | null;
      sizeAsMultipleOfAdtv?: number | null;
      isCarveOut?: boolean | null;
      isCompanyRepurchaseIncluded?: boolean | null;
      isCompanyRepurchaseAdditional?: boolean | null;
      isConcurrentOffering?: boolean | null;
      isCleanUpTrade?: boolean | null;
      isFirstFollowOn?: boolean | null;
      isSyntheticSecondary?: boolean | null;
      isUpListing?: boolean | null;
      useOfProceedsNote?: string | null;
      pctFileToOffer?: number | null;
      lastTradeBeforeLaunch?: number | null;
      pctLastTradeBeforeLaunch?: number | null;
      lastTradeBeforeOffer?: number | null;
      pctToLastTrade?: number | null;
      vwapPrice?: number | null;
      pctToVwap?: number | null;
      fiftyTwoWeekHigh?: number | null;
      fiftyTwoWeekHighDate?: string | null;
      pctTo52WeekHigh?: number | null;
      reOfferLow?: number | null;
      reOfferHigh?: number | null;
      pctReOfferLow?: number | null;
      pctReOfferHigh?: number | null;
      initialSizeInSecuritiesBase?: number | null;
      overAllotmentExercised?: number | null;
      overAllotmentExercisedPrimary?: number | null;
      overAllotmentExercisedSecondary?: number | null;
      initialIpoRangeLow?: number | null;
      initialIpoRangeHigh?: number | null;
      revisedIpoRangeLow?: number | null;
      revisedIpoRangeHigh?: number | null;
      cornerstoneInvestorsOfferingParticipantsCount?: number | null;
      cornerstoneInvestorsPrivatePlacementCount?: number | null;
      cornerstoneInvestorsTotalCount?: number | null;
      cornerstoneOfferingParticipantsAmount?: number | null;
      cornerstonePrivatePlacementAmount?: number | null;
      cornerstoneTotalAmount?: number | null;
      hasCornerstoneInvestors?: boolean | null;
      overAllotmentExercisedProceedsNotional?: number | null;
      latestOverAllotmentAuthorized?: number | null;
      latestOverAllotmentAuthorizedPrimary?: number | null;
      latestOverAllotmentAuthorizedSecondary?: number | null;
    } | null;
    atmAttributes?: {
      __typename?: 'AtmAttributes';
      pctGrossSpread?: number | null;
      lastTradeBeforeFilingSplitAdjusted?: number | null;
      latestAsOfDate?: string | null;
      latestProgramSize?: number | null;
      latestProgramSizeInSecurities?: number | null;
      announcedProgramSize?: number | null;
      announcedProgramSizeInSecurities?: number | null;
      totalSales?: number | null;
      totalSalesInSecurities?: number | null;
      totalProgramRemaining?: number | null;
      totalProgramRemainingInSecurities?: number | null;
      structure: Array<{
        __typename?: 'AtmStructure';
        selldownId?: string | null;
        programRemainingInSecurities?: number | null;
        type: Types.AtmStructureType;
        typeDisplayName?: string | null;
        announcementDate?: string | null;
        asOfDate?: string | null;
        sales?: number | null;
        salesInSecurities?: number | null;
        averagePrice?: number | null;
        programSize?: number | null;
        programSizeInSecurities?: number | null;
        programRemaining?: number | null;
      }>;
    } | null;
    initialTerm?: {
      __typename?: 'Term';
      createdAt: string;
      aggregatePrincipalAmount?: number | null;
      principalAmountOverallotmentAuthorized?: number | null;
      principalAmountOverallotmentExercised?: number | null;
      aggregatePrincipalInclOverallotmentExercised?: number | null;
      couponTalkPercentageLow?: number | null;
      couponTalkPercentageHigh?: number | null;
      premiumTalkLowPercentage?: number | null;
      premiumTalkHighPercentage?: number | null;
      offerPrice?: number | null;
      principalAmountPerNote?: number | null;
      conversionReferencePrice?: number | null;
      conversionPrice?: number | null;
      effectiveConversionPrice?: number | null;
      effectiveConversionPremium?: number | null;
      versusTalkDisplayName?: string | null;
      pctOfferPrice?: number | null;
      conversionRatio?: number | null;
      premiumPercentage?: number | null;
      couponPercentage?: number | null;
      sizeInSecuritiesBase?: number | null;
      overAllotmentAuthorized?: number | null;
      overAllotmentAuthorizedPrimary?: number | null;
      overAllotmentAuthorizedSecondary?: number | null;
      sizeInSecuritiesBasePrimary?: number | null;
      sizeInSecuritiesBaseSecondary?: number | null;
      ipoRangeLow?: number | null;
      ipoRangeHigh?: number | null;
      revisedDate?: string | null;
      grossProceedsBase?: number | null;
    } | null;
    latestRevisedTerm?: {
      __typename?: 'Term';
      createdAt: string;
      aggregatePrincipalAmount?: number | null;
      principalAmountOverallotmentAuthorized?: number | null;
      principalAmountOverallotmentExercised?: number | null;
      aggregatePrincipalInclOverallotmentExercised?: number | null;
      couponTalkPercentageLow?: number | null;
      couponTalkPercentageHigh?: number | null;
      premiumTalkLowPercentage?: number | null;
      premiumTalkHighPercentage?: number | null;
      offerPrice?: number | null;
      principalAmountPerNote?: number | null;
      conversionReferencePrice?: number | null;
      conversionPrice?: number | null;
      effectiveConversionPrice?: number | null;
      effectiveConversionPremium?: number | null;
      versusTalkDisplayName?: string | null;
      pctOfferPrice?: number | null;
      conversionRatio?: number | null;
      premiumPercentage?: number | null;
      couponPercentage?: number | null;
      sizeInSecuritiesBase?: number | null;
      overAllotmentAuthorized?: number | null;
      overAllotmentAuthorizedPrimary?: number | null;
      overAllotmentAuthorizedSecondary?: number | null;
      sizeInSecuritiesBasePrimary?: number | null;
      sizeInSecuritiesBaseSecondary?: number | null;
      ipoRangeLow?: number | null;
      ipoRangeHigh?: number | null;
      revisedDate?: string | null;
      grossProceedsBase?: number | null;
    } | null;
    finalTerm?: {
      __typename?: 'Term';
      createdAt: string;
      aggregatePrincipalAmount?: number | null;
      principalAmountOverallotmentAuthorized?: number | null;
      principalAmountOverallotmentExercised?: number | null;
      aggregatePrincipalInclOverallotmentExercised?: number | null;
      couponTalkPercentageLow?: number | null;
      couponTalkPercentageHigh?: number | null;
      premiumTalkLowPercentage?: number | null;
      premiumTalkHighPercentage?: number | null;
      offerPrice?: number | null;
      principalAmountPerNote?: number | null;
      conversionReferencePrice?: number | null;
      conversionPrice?: number | null;
      effectiveConversionPrice?: number | null;
      effectiveConversionPremium?: number | null;
      versusTalkDisplayName?: string | null;
      pctOfferPrice?: number | null;
      conversionRatio?: number | null;
      premiumPercentage?: number | null;
      couponPercentage?: number | null;
      sizeInSecuritiesBase?: number | null;
      overAllotmentAuthorized?: number | null;
      overAllotmentAuthorizedPrimary?: number | null;
      overAllotmentAuthorizedSecondary?: number | null;
      sizeInSecuritiesBasePrimary?: number | null;
      sizeInSecuritiesBaseSecondary?: number | null;
      ipoRangeLow?: number | null;
      ipoRangeHigh?: number | null;
      revisedDate?: string | null;
      grossProceedsBase?: number | null;
    } | null;
    terms: Array<{
      __typename?: 'Term';
      id?: string | null;
      type?: Types.TermType | null;
      createdAt: string;
      aggregatePrincipalAmount?: number | null;
      principalAmountOverallotmentAuthorized?: number | null;
      principalAmountOverallotmentExercised?: number | null;
      aggregatePrincipalInclOverallotmentExercised?: number | null;
      couponTalkPercentageLow?: number | null;
      couponTalkPercentageHigh?: number | null;
      premiumTalkLowPercentage?: number | null;
      premiumTalkHighPercentage?: number | null;
      offerPrice?: number | null;
      principalAmountPerNote?: number | null;
      conversionReferencePrice?: number | null;
      conversionPrice?: number | null;
      effectiveConversionPrice?: number | null;
      effectiveConversionPremium?: number | null;
      versusTalkDisplayName?: string | null;
      pctOfferPrice?: number | null;
      conversionRatio?: number | null;
      premiumPercentage?: number | null;
      couponPercentage?: number | null;
      sizeInSecuritiesBase?: number | null;
      overAllotmentAuthorized?: number | null;
      overAllotmentAuthorizedPrimary?: number | null;
      overAllotmentAuthorizedSecondary?: number | null;
      sizeInSecuritiesBasePrimary?: number | null;
      sizeInSecuritiesBaseSecondary?: number | null;
      ipoRangeLow?: number | null;
      ipoRangeHigh?: number | null;
      revisedDate?: string | null;
      grossProceedsBase?: number | null;
      atmProgramSize?: number | null;
      atmProgramSizeInSecurities?: number | null;
    }>;
    advisers?: Array<{
      __typename?: 'OfferingAdviser';
      name?: string | null;
      role?: Types.AdviserRole | null;
      roleDisplayName?: string | null;
    }> | null;
    managers: Array<{
      __typename?: 'OfferingManager';
      pctUnderwritingBaseShares?: number | null;
      underwritingBaseShares?: number | null;
      name?: string | null;
      role?: Types.OfferingManagerRole | null;
      roleDisplayName?: string | null;
      sequence?: number | null;
      managerId?: string | null;
      estimatedFee?: number | null;
    }>;
    shareholders?: Array<{
      __typename?: 'OfferingShareholder';
      name?: string | null;
      shareholderTypeDisplayName?: string | null;
      preOfferingOwnership?: number | null;
      pctPreOfferingOwnership?: number | null;
      postOfferingOwnership?: number | null;
      pctPostOfferingOwnership?: number | null;
      postOfferingOwnershipIncludingShoe?: number | null;
      pctPostOfferingOwnershipIncludingShoe?: number | null;
      isCarveOutParent?: boolean | null;
    }> | null;
    news?: Array<{
      __typename?: 'SecFiling';
      filingDate?: string | null;
      filingType?: string | null;
      description?: string | null;
      fileUrl?: string | null;
    }> | null;
    lockUps?: Array<{
      __typename?: 'LockUp';
      benchMarkDate?: string | null;
      earlyReleaseDate?: string | null;
      expirationDate?: string | null;
      period: number;
      unit: Types.LockUpUnit;
      unitDisplayName?: string | null;
    }> | null;
    documents: Array<{
      __typename?: 'Document';
      type: Types.DocumentType;
      typeDisplayName?: string | null;
      link: any;
      name?: string | null;
      publicationDate?: string | null;
    }>;
    cornerstoneInvestments?: Array<{
      __typename?: 'CornerstoneInvestment';
      individualAmount?: number | null;
      totalInvestors: number;
      type: Types.CornerstoneInvestmentType;
      typeDisplayName?: string | null;
      investors: Array<{
        __typename?: 'CornerstoneInvestor';
        cornerstoneInvestmentId: string;
        isExistingCornerstoneInvestor: boolean;
        shareholder: {
          __typename?: 'Shareholder';
          name?: string | null;
          shareholderTypeDisplayName?: string | null;
        };
        cornerstoneInvestment: {
          __typename?: 'CornerstoneInvestment';
          individualAmount?: number | null;
          totalInvestors: number;
          type: Types.CornerstoneInvestmentType;
          typeDisplayName?: string | null;
        };
      }>;
    }> | null;
    instruments: Array<{
      __typename?: 'Instrument';
      depositaryReceiptConversionNumerator?: number | null;
      depositaryReceiptConversionDenominator?: number | null;
      conversionMultiplier?: number | null;
    }>;
  } | null;
};

export const OfferingProfile_Terms_PartFragmentDoc = gql`
  fragment OfferingProfile_Terms_Part on Term {
    createdAt
    aggregatePrincipalAmount
    principalAmountOverallotmentAuthorized
    principalAmountOverallotmentExercised
    aggregatePrincipalInclOverallotmentExercised
    couponTalkPercentageLow
    couponTalkPercentageHigh
    premiumTalkLowPercentage
    premiumTalkHighPercentage
    offerPrice
    principalAmountPerNote
    conversionReferencePrice
    conversionPrice
    effectiveConversionPrice
    effectiveConversionPremium
    versusTalkDisplayName
    pctOfferPrice
    conversionRatio
    premiumPercentage
    couponPercentage
    sizeInSecuritiesBase
    overAllotmentAuthorized
    overAllotmentAuthorizedPrimary
    overAllotmentAuthorizedSecondary
    sizeInSecuritiesBasePrimary
    sizeInSecuritiesBaseSecondary
    ipoRangeLow
    ipoRangeHigh
    revisedDate
    grossProceedsBase
  }
`;
export const OfferingProfile_OfferingShareholder_PartFragmentDoc = gql`
  fragment OfferingProfile_OfferingShareholder_Part on OfferingShareholder {
    name
    shareholderTypeDisplayName
    preOfferingOwnership
    pctPreOfferingOwnership
    postOfferingOwnership
    pctPostOfferingOwnership
    postOfferingOwnershipIncludingShoe
    pctPostOfferingOwnershipIncludingShoe
    isCarveOutParent
  }
`;
export const OfferingProfile_Document_PartFragmentDoc = gql`
  fragment OfferingProfile_Document_Part on Document {
    type
    typeDisplayName
    link
    name
    publicationDate
  }
`;
export const OfferingProfile_CornerstoneInvestment_PartFragmentDoc = gql`
  fragment OfferingProfile_CornerstoneInvestment_Part on CornerstoneInvestment {
    individualAmount
    totalInvestors
    type
    typeDisplayName
    investors {
      cornerstoneInvestmentId
      isExistingCornerstoneInvestor
      shareholder {
        name
        shareholderTypeDisplayName
      }
      cornerstoneInvestment {
        individualAmount
        totalInvestors
        type
        typeDisplayName
      }
    }
  }
`;
export const OfferingProfile_ListDocument = gql`
  query OfferingProfile_List(
    $offeringId: ID!
    $isConvert: Boolean!
    $isAtm: Boolean!
    $hasSellDownPermission: Boolean!
    $hasMySectorsPermission: Boolean!
  ) {
    offeringById(id: $offeringId) {
      issuer {
        id
        name
        sectorDisplayName
        subSectorDisplayName
        entityTypes
        primarySymbol
        customSectors @include(if: $hasMySectorsPermission) {
          customSector {
            name
          }
          customSubSector {
            name
          }
        }
      }
      type
      isExecutingOnPlatform
      exchange
      exchangeMic
      exchangeMicDisplayName
      exchangeRegion
      exchangeCountry
      symbol
      typeDisplayName
      status
      statusDisplayName
      pricingCurrency
      exchangeRegionDisplayName
      exchangeCountryDisplayName
      exchangeCountry
      marketTimingDisplayName
      securityType
      securityTypeDisplayName
      isRule144A
      publicFilingDate
      lastTradeBeforeFiling
      hasForwardAgreement
      terminatedDate
      launchDate
      pricingDate
      firstTradeDate
      settlementDate
      maturityDate
      withdrawnDate
      postponedDate
      price
      convertibleAttributes @include(if: $isConvert) {
        isCallable
        callableDate
        callPrice
        tenor
        rankDisplayName
        hasProvisionalCall
        isPuttable
        hasContingentConversion
        hasContingentPayment
        hasDividendProtection
        isHedging
        isPerpetual
        securityNote
        dividendProtectionNote
        putNote
        provisionalCallNote
        contingentConversionNote
        contingentPaymentNote
        hedgingNote
        changeOfControl
        underlyingSymbol
      }
      attributes {
        isSpac
        pricingCurrency
        sizeUsd
        latestSizeInSecuritiesTotal
        marketCapPreOffering
        pctMarketCapPreOffering
        marketCapAtPricing
        pctMarketCapAtPricing
        preOfferingAdtv
        adtvRangeLowDate
        adtvRangeHighDate
        initialGrossProceedsBase
        revisedGrossProceedsBase
        latestGrossProceedsBase
        latestGrossProceedsTotal
        latestSizeInSecuritiesBase
        latestSizeInSecuritiesTotalPrimary
        latestSizeInSecuritiesTotalSecondary
        priceUsd
        leftLeadName
        stabilizationAgentName
        settlementAgentName
        pctGrossSpread
        grossSpreadTotalUsd
        grossSpreadTotal
        sellingConcessionUsd
        managementFeeUsd
        underwritingFeeUsd
        lockUpPeriod
        lockUpExpirationDate
        lockUpEarlyReleaseDate
        isConditionalLockUp
        hasMultipleLockUps
        lastTradeBeforeFiling
        firstDayVolume
        firstDayTurnover
        offerToVwap1Day
        pctOfferToOpen
        pctOfferTo1Day
        pctOfferTo3Day
        pctOfferTo7Day
        pctOfferTo14Day
        pctOfferTo30Day
        pctOfferTo90Day
        pctOfferTo180Day
        pctOfferToOneYear
        pctOfferToCurrent
        pctOfferToPriorQuarter
        day1VwapPrice
        openingSharePrice
        currentSharePrice
        day1SharePrice
        day3SharePrice
        day7SharePrice
        day14SharePrice
        day30SharePrice
        day90SharePrice
        day180SharePrice
        oneYearSharePrice
        priorQuarterSharePrice
        isDualListed
        isFdicRegistered
        isSecRegistered
        latestPctSecondaryShares
        confidentialFilingDate
        isAccelerated
        isRegS
        isRule144A
        totalManagers
        totalBookrunners
        totalNonBookrunners
        estimatedFeeUsd
        netPrice
        pctAllInCost
        allInCost
        totalPctToActiveManagers
        totalPctToCoManagers
        totalPctToBookrunners
        totalPctToNonBookrunners
        totalPctToLeftLead
        lockUpPeriod
        lockUpExpirationDate
        lockUpEarlyReleaseDate
        preOfferingShares
        latestPostOfferingShares
        splitAdjustedOfferPrice
        pctVsMidpoint
        pctChangeInSize
        priceVsRange
        priceVsRangeDisplayName
        sizeAsMultipleOfAdtv
        isCarveOut
        isCompanyRepurchaseIncluded
        isCompanyRepurchaseAdditional
        isConcurrentOffering
        isCleanUpTrade
        isFirstFollowOn
        isSyntheticSecondary
        isUpListing
        useOfProceedsNote
        lastTradeBeforeFiling
        pctFileToOffer
        lastTradeBeforeLaunch
        pctLastTradeBeforeLaunch
        lastTradeBeforeOffer
        pctToLastTrade
        vwapPrice
        pctToVwap
        fiftyTwoWeekHigh
        fiftyTwoWeekHighDate
        pctTo52WeekHigh
        reOfferLow
        reOfferHigh
        pctReOfferLow
        pctReOfferHigh
        netPrice
        initialSizeInSecuritiesBase
        overAllotmentExercised
        overAllotmentExercisedPrimary
        overAllotmentExercisedSecondary
        initialIpoRangeLow
        initialIpoRangeHigh
        revisedIpoRangeLow
        revisedIpoRangeHigh
        cornerstoneInvestorsOfferingParticipantsCount
        cornerstoneInvestorsPrivatePlacementCount
        cornerstoneInvestorsTotalCount
        cornerstoneOfferingParticipantsAmount
        cornerstonePrivatePlacementAmount
        cornerstoneTotalAmount
        hasCornerstoneInvestors
        overAllotmentExercisedProceedsNotional
        latestOverAllotmentAuthorized
        latestOverAllotmentAuthorizedPrimary
        latestOverAllotmentAuthorizedSecondary
      }
      atmAttributes @include(if: $isAtm) {
        ...Atm_Attributes_Fields
        ...Atm_Selldown_Fields @include(if: $hasSellDownPermission)
      }
      initialTermId
      latestRevisedTermId
      finalTermId
      initialTerm {
        ...OfferingProfile_Terms_Part
      }
      latestRevisedTerm {
        ...OfferingProfile_Terms_Part
      }
      finalTerm {
        ...OfferingProfile_Terms_Part
      }
      terms {
        id
        type
        ...OfferingProfile_Terms_Part
        ...Atm_Selldown_Term_Fields @include(if: $isAtm)
      }
      useOfProceedsDisplayNames
      advisers {
        name
        role
        roleDisplayName
      }
      managers {
        pctUnderwritingBaseShares
        underwritingBaseShares
        name
        role
        roleDisplayName
        sequence
        managerId
        estimatedFee
      }
      shareholders {
        ...OfferingProfile_OfferingShareholder_Part
      }
      news {
        filingDate
        filingType
        description
        fileUrl
      }
      lockUps {
        benchMarkDate
        earlyReleaseDate
        expirationDate
        period
        unit
        unitDisplayName
      }
      documents {
        ...OfferingProfile_Document_Part
      }
      cornerstoneInvestments {
        ...OfferingProfile_CornerstoneInvestment_Part
      }
      instruments {
        depositaryReceiptConversionNumerator
        depositaryReceiptConversionDenominator
        conversionMultiplier
      }
    }
  }
  ${Atm_Attributes_FieldsFragmentDoc}
  ${Atm_Selldown_FieldsFragmentDoc}
  ${OfferingProfile_Terms_PartFragmentDoc}
  ${Atm_Selldown_Term_FieldsFragmentDoc}
  ${OfferingProfile_OfferingShareholder_PartFragmentDoc}
  ${OfferingProfile_Document_PartFragmentDoc}
  ${OfferingProfile_CornerstoneInvestment_PartFragmentDoc}
`;

/**
 * __useOfferingProfile_ListQuery__
 *
 * To run a query within a React component, call `useOfferingProfile_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferingProfile_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferingProfile_ListQuery({
 *   variables: {
 *      offeringId: // value for 'offeringId'
 *      isConvert: // value for 'isConvert'
 *      isAtm: // value for 'isAtm'
 *      hasSellDownPermission: // value for 'hasSellDownPermission'
 *      hasMySectorsPermission: // value for 'hasMySectorsPermission'
 *   },
 * });
 */
export function useOfferingProfile_ListQuery(
  baseOptions: Apollo.QueryHookOptions<
    OfferingProfile_ListQuery,
    OfferingProfile_ListQueryVariables
  > &
    ({ variables: OfferingProfile_ListQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferingProfile_ListQuery, OfferingProfile_ListQueryVariables>(
    OfferingProfile_ListDocument,
    options
  );
}
export function useOfferingProfile_ListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OfferingProfile_ListQuery,
    OfferingProfile_ListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferingProfile_ListQuery, OfferingProfile_ListQueryVariables>(
    OfferingProfile_ListDocument,
    options
  );
}
export function useOfferingProfile_ListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<OfferingProfile_ListQuery, OfferingProfile_ListQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OfferingProfile_ListQuery, OfferingProfile_ListQueryVariables>(
    OfferingProfile_ListDocument,
    options
  );
}
export type OfferingProfile_ListQueryHookResult = ReturnType<typeof useOfferingProfile_ListQuery>;
export type OfferingProfile_ListLazyQueryHookResult = ReturnType<
  typeof useOfferingProfile_ListLazyQuery
>;
export type OfferingProfile_ListSuspenseQueryHookResult = ReturnType<
  typeof useOfferingProfile_ListSuspenseQuery
>;
export type OfferingProfile_ListQueryResult = Apollo.QueryResult<
  OfferingProfile_ListQuery,
  OfferingProfile_ListQueryVariables
>;
