import { ColDef } from '@cmg/common';

import { SortEnumType } from '../../../../graphql/__generated__/index';
import { NestedSortInput } from '../../../../graphql/types';
import { dataGridColumns } from './OfferingsReportTable.model';
import { Offering } from './types';

const convertibleAttributesSortModel = (sortModel: NestedSortInput) => ({
  convertibleAttributes: sortModel,
});

const attributesSortModel = (sortModel: NestedSortInput) => ({
  attributes: sortModel,
});

export const sortModelMappings: { [field: string]: (sort: SortEnumType) => NestedSortInput } = {
  issuer_name: sort => ({ issuer: { name: sort } }),
  issuer_sectorDisplayName: sort => ({ issuer: { sector: { name: sort } } }),
  typeDisplayName: sort => ({ type: { name: sort } }),
  securityTypeDisplayName: sort => ({ securityType: { name: sort } }),
  attributes_priceUsd: sort => attributesSortModel({ priceUsd: sort }),
  attributes_latestGrossProceedsBaseUsd: sort =>
    attributesSortModel({ latestGrossProceedsBaseUsd: sort }),
  attributes_latestGrossProceedsTotalUsd: sort =>
    attributesSortModel({ latestGrossProceedsTotalUsd: sort }),
  // attributes_notionalOverAllotmentExercised: sort =>
  //   attributesSortModel({ notionalOverAllotmentExercised: sort } ), // field not available yet
  attributes_pctGrossSpread: sort => attributesSortModel({ pctGrossSpread: sort }),
  attributes_grossSpreadTotalUsd: sort => attributesSortModel({ grossSpreadTotalUsd: sort }),
  exchangeRegionDisplayName: sort => ({
    exchangeRegion: { displayName: sort },
  }),
  exchangeCountryDisplayName: sort => ({
    exchangeCountry: { displayName: sort },
  }),
  exchangeMicDisplayName: sort => ({ exchangeMic: { displayName: sort } }),
  pricingCurrencyDisplayName: sort => ({
    pricingCurrency: { displayName: sort },
  }),
  leftLead: sort => attributesSortModel({ leftLeadName: sort }),
  // publicFilingDate: sort => ({ publicFilingDate: sort }),
  announcementDate: sort => ({ publicFilingDate: sort }),
  marketTimingDisplayName: sort => ({
    marketTiming: { displayName: sort },
  }),
  announcementTime: sort => ({
    marketTiming: { displayName: sort },
  }),
  attributes_terminatedDate: sort => attributesSortModel({ terminatedDate: sort }),
  issuer_primarySymbol: sort => ({ issuer: { primarySymbol: sort } }),
  issuer_cik: sort => ({ issuer: { cik: sort } }),
  statusDisplayName: sort => ({ status: { name: sort } }),
  attributes_latestSizeInSecuritiesTotal: sort => ({
    attributes: { latestSizeInSecuritiesTotal: sort },
  }),
  attributes_marketCapPreOffering: sort => attributesSortModel({ marketCapPreOffering: sort }),
  attributes_pctMarketCapPreOffering: sort =>
    attributesSortModel({ pctMarketCapPreOffering: sort }),
  attributes_preOfferingAdtv: sort => attributesSortModel({ preOfferingAdtv: sort }),
  attributes_lastTradeBeforeFilingUsd: sort =>
    attributesSortModel({ lastTradeBeforeFilingUsd: sort }),
  convertibleAttributes_underlyingSymbol: sort =>
    convertibleAttributesSortModel({ underlyingSymbol: sort }),
  convertibleAttributes_securityNote: sort =>
    convertibleAttributesSortModel({ securityNote: sort }),
  convertibleAttributes_rankDisplayName: sort =>
    convertibleAttributesSortModel({ rank: { displayName: sort } }),
  convertibleAttributes_isPerpetual: sort => convertibleAttributesSortModel({ isPerpetual: sort }),
  convertibleAttributes_tenor: sort => convertibleAttributesSortModel({ tenor: sort }),
  convertibleAttributes_hasDividendProtection: sort =>
    convertibleAttributesSortModel({ hasDividendProtection: sort }),
  convertibleAttributes_dividendProtectionNote: sort =>
    convertibleAttributesSortModel({ dividendProtectionNote: sort }),
  convertibleAttributes_isCallable: sort => convertibleAttributesSortModel({ isCallable: sort }),
  convertibleAttributes_callableDate: sort =>
    convertibleAttributesSortModel({ callableDate: sort }),
  convertibleAttributes_callPrice: sort => convertibleAttributesSortModel({ callPrice: sort }),
  convertibleAttributes_hasProvisionalCall: sort =>
    convertibleAttributesSortModel({ hasProvisionalCall: sort }),
  convertibleAttributes_provisionalCallNote: sort =>
    convertibleAttributesSortModel({ provisionalCallNote: sort }),
  convertibleAttributes_isPuttable: sort => convertibleAttributesSortModel({ isPuttable: sort }),
  convertibleAttributes_putNote: sort => convertibleAttributesSortModel({ putNote: sort }),
  convertibleAttributes_hasContingentConversion: sort =>
    convertibleAttributesSortModel({ hasContingentConversion: sort }),
  convertibleAttributes_contingentConversionNote: sort =>
    convertibleAttributesSortModel({ contingentConversionNote: sort }),
  convertibleAttributes_hasContingentPayment: sort =>
    convertibleAttributesSortModel({ hasContingentPayment: sort }),
  convertibleAttributes_contingentPaymentNote: sort =>
    convertibleAttributesSortModel({ contingentPaymentNote: sort }),
  convertibleAttributes_isHedging: sort => convertibleAttributesSortModel({ isHedging: sort }),
  convertibleAttributes_hedgingNote: sort => convertibleAttributesSortModel({ hedgingNote: sort }),
  convertibleAttributes_changeOfControl: sort =>
    convertibleAttributesSortModel({ changeOfControl: sort }),
  atmAttributes_effectiveDate: sort => ({ atmAttributes: { effectiveDate: sort } }),
  atmAttributes_pctGrossSpread: sort => ({ atmAttributes: { pctGrossSpread: sort } }),
  atmAttributes_lastTradeBeforeFilingSplitAdjusted: sort => ({
    atmAttributes: { lastTradeBeforeFilingSplitAdjusted: sort },
  }),
  atmAttributes_totalProgramRemaining: sort => ({ atmAttributes: { totalProgramRemaining: sort } }),
  atmAttributes_totalProgramRemainingInSecurities: sort => ({
    atmAttributes: { totalProgramRemainingInSecurities: sort },
  }),
  atmAttributes_latestProgramSize: sort => ({ atmAttributes: { latestProgramSize: sort } }),
  atmAttributes_latestProgramSizeInSecurities: sort => ({
    atmAttributes: { latestProgramSizeInSecurities: sort },
  }),
  atmAttributes_announcedProgramSize: sort => ({ atmAttributes: { announcedProgramSize: sort } }),
  atmAttributes_announcedProgramSizeInSecurities: sort => ({
    atmAttributes: { announcedProgramSizeInSecurities: sort },
  }),
  attributes_pctOfferToOpen: sort => attributesSortModel({ pctOfferToOpen: sort }),
  attributes_pctOfferTo1Day: sort => attributesSortModel({ pctOfferTo1Day: sort }),
  attributes_pctOfferTo3Day: sort => attributesSortModel({ pctOfferTo3Day: sort }),
  attributes_pctOfferTo7Day: sort => attributesSortModel({ pctOfferTo7Day: sort }),
  attributes_pctOfferTo14Day: sort => attributesSortModel({ pctOfferTo14Day: sort }),
  attributes_pctOfferTo30Day: sort => attributesSortModel({ pctOfferTo30Day: sort }),
  attributes_pctOfferTo90Day: sort => attributesSortModel({ pctOfferTo90Day: sort }),
  attributes_pctOfferTo180Day: sort => attributesSortModel({ pctOfferTo180Day: sort }),
  attributes_pctOfferToCurrent: sort => attributesSortModel({ pctOfferToCurrent: sort }),
  attributes_netPrice: sort => attributesSortModel({ netPrice: sort }),
  attributes_splitAdjustedOfferPrice: sort =>
    attributesSortModel({ splitAdjustedOfferPrice: sort }),
  attributes_latestSizeInSecuritiesBase: sort =>
    attributesSortModel({ latestSizeInSecuritiesBase: sort }),
  attributes_initialSizeInSecuritiesBase: sort =>
    attributesSortModel({ initialSizeInSecuritiesBase: sort }),
  attributes_latestSizeInSecuritiesBasePrimary: sort =>
    attributesSortModel({ latestSizeInSecuritiesBasePrimary: sort }),
  attributes_latestSizeInSecuritiesBaseSecondary: sort =>
    attributesSortModel({ latestSizeInSecuritiesBaseSecondary: sort }),
  attributes_latestPctSecondaryShares: sort =>
    attributesSortModel({ latestPctSecondaryShares: sort }),
  attributes_latestOverAllotmentAuthorized: sort =>
    attributesSortModel({ latestOverAllotmentAuthorized: sort }),
  attributes_overAllotmentExercised: sort => attributesSortModel({ overAllotmentExercised: sort }),
  attributes_initialGrossProceedsBaseUsd: sort =>
    attributesSortModel({ initialGrossProceedsBaseUsd: sort }),
  attributes_revisedGrossProceedsBaseUsd: sort =>
    attributesSortModel({ revisedGrossProceedsBaseUsd: sort }),
  attributes_marketCapAtPricingUsd: sort => attributesSortModel({ marketCapAtPricingUsd: sort }),
  attributes_marketCapPreOfferingUsd: sort =>
    attributesSortModel({ marketCapPreOfferingUsd: sort }),
  attributes_latestPostOfferingShares: sort =>
    attributesSortModel({ latestPostOfferingShares: sort }),
  attributes_preOfferingShares: sort => attributesSortModel({ preOfferingShares: sort }),
  attributes_pctMarketCapAtPricing: sort => attributesSortModel({ pctMarketCapAtPricing: sort }),
  attributes_sizeAsMultipleOfAdtv: sort => attributesSortModel({ sizeAsMultipleOfAdtv: sort }),
  attributes_pctFileToOffer: sort => attributesSortModel({ pctFileToOffer: sort }),
  attributes_pctToLastTrade: sort => attributesSortModel({ pctToLastTrade: sort }),
  attributes_pctToVwap: sort => attributesSortModel({ pctToVwap: sort }),
  attributes_pctTo52WeekHigh: sort => attributesSortModel({ pctTo52WeekHigh: sort }),
  attributes_allInCost: sort => attributesSortModel({ allInCost: sort }),
  attributes_estimatedFeeUsd: sort => attributesSortModel({ estimatedFeeUsd: sort }),
  attributes_initialIndicativeGrossProceedsUsd: sort =>
    attributesSortModel({ initialIndicativeGrossProceedsUsd: sort }),
  attributes_priceVsRangeDisplayName: sort =>
    attributesSortModel({ priceVsRange: { displayName: sort } }),
  attributes_pctVsMidpoint: sort => attributesSortModel({ pctVsMidpoint: sort }),
  attributes_pctChangeInSize: sort => attributesSortModel({ pctChangeInSize: sort }),
  attributes_lastTradeBeforeOfferUsd: sort =>
    attributesSortModel({ lastTradeBeforeOfferUsd: sort }),
  attributes_fiftyTwoWeekHigh: sort => attributesSortModel({ fiftyTwoWeekHigh: sort }),
  attributes_reOfferLowUsd: sort => attributesSortModel({ reOfferLowUsd: sort }),
  attributes_reOfferHighUsd: sort => attributesSortModel({ reOfferHighUsd: sort }),
  attributes_pctReOfferHigh: sort => attributesSortModel({ pctReOfferHigh: sort }),
  attributes_pctReOfferLow: sort => attributesSortModel({ pctReOfferLow: sort }),
  attributes_firstDayVolume: sort => attributesSortModel({ firstDayVolume: sort }),
  attributes_firstDayTurnover: sort => attributesSortModel({ firstDayTurnover: sort }),
  attributes_offerToVwap1Day: sort => attributesSortModel({ offerToVwap1Day: sort }),
  attributes_pctOfferToOneYear: sort => attributesSortModel({ pctOfferToOneYear: sort }),
  attributes_pctOfferToPriorQuarter: sort => attributesSortModel({ pctOfferToPriorQuarter: sort }),
  attributes_totalManagers: sort => attributesSortModel({ totalManagers: sort }),
  attributes_totalBookrunners: sort => attributesSortModel({ totalBookrunners: sort }),
  attributes_totalNonBookrunners: sort => attributesSortModel({ totalNonBookrunners: sort }),
  attributes_totalPctToBookrunners: sort => attributesSortModel({ totalPctToBookrunners: sort }),
  attributes_totalPctToNonBookrunners: sort =>
    attributesSortModel({ totalPctToNonBookrunners: sort }),
  attributes_totalPctToLeftLead: sort => attributesSortModel({ totalPctToLeftLead: sort }),
  attributes_lockUpPeriod: sort => attributesSortModel({ lockUpPeriod: sort }),
  attributes_lockUpExpirationDate: sort => attributesSortModel({ lockUpExpirationDate: sort }),
  attributes_lockUpEarlyReleaseDate: sort => attributesSortModel({ lockUpEarlyReleaseDate: sort }),
  attributes_isConditionalLockUp: sort => attributesSortModel({ isConditionalLockUp: sort }),
  attributes_hasMultipleLockUps: sort => attributesSortModel({ hasMultipleLockUps: sort }),
  attributes_totalPreOfferingOwnership: sort =>
    attributesSortModel({ totalPreOfferingOwnership: sort }),
  attributes_totalPctPreOfferingOwnership: sort =>
    attributesSortModel({ totalPctPreOfferingOwnership: sort }),
  attributes_totalPostOfferingOwnership: sort =>
    attributesSortModel({ totalPostOfferingOwnership: sort }),
  attributes_totalPctPostOfferingOwnership: sort =>
    attributesSortModel({ totalPctPostOfferingOwnership: sort }),
  attributes_isFirstFollowOn: sort => attributesSortModel({ isFirstFollowOn: sort }),
  attributes_isCarveOut: sort => attributesSortModel({ isCarveOut: sort }),
  attributes_isCleanUpTrade: sort => attributesSortModel({ isCleanUpTrade: sort }),
  attributes_isConcurrentOffering: sort => attributesSortModel({ isConcurrentOffering: sort }),
  attributes_isDualListed: sort => attributesSortModel({ isDualListed: sort }),
  attributes_isEgc: sort => attributesSortModel({ isEgc: sort }),
  attributes_isSyntheticSecondary: sort => attributesSortModel({ isSyntheticSecondary: sort }),
  attributes_isUpListing: sort => attributesSortModel({ isUpListing: sort }),
  attributes_useOfProceedsNote: sort => attributesSortModel({ useOfProceedsNote: sort }),
  attributes_isCompanyRepurchaseAdditional: sort =>
    attributesSortModel({ isCompanyRepurchaseAdditional: sort }),
  attributes_isCompanyRepurchaseIncluded: sort =>
    attributesSortModel({ isCompanyRepurchaseIncluded: sort }),
  attributes_hasCornerstoneInvestors: sort =>
    attributesSortModel({ hasCornerstoneInvestors: sort }),
  attributes_cornerstoneInvestorsTotalCount: sort =>
    attributesSortModel({ cornerstoneInvestorsTotalCount: sort }),
  issuer_headquarters: sort => ({ issuer: { headquarters: sort } }),
  issuer_naics: sort => ({ issuer: { naics: sort } }),
  issuer_subSectorDisplayName: sort => ({ issuer: { subSector: { displayName: sort } } }),
  attributes_confidentialFilingDate: sort => attributesSortModel({ confidentialFilingDate: sort }),
  shareholder: sort => attributesSortModel({ primaryShareholderName: sort }),
};
export const getColumnById = (columnName: string): ColDef<Offering, any> =>
  dataGridColumns[columnName] ?? {};
