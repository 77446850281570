import { ColDef } from '@cmg/common';

import {
  CustomSectorIssuerFilterInput,
  IssuerFilterInput,
  OfferingFilterInput,
  OfferingStatus,
  Sector,
  SortEnumType,
  SubSector,
} from '../../../graphql/__generated__/index';
import { CategorizedColumn, NestedSortInput } from '../../../graphql/types';
import {
  columnCategoryLabel,
  columnGroupLabel,
  DatalabTableColumnCategory,
  DatalabTableColumnGroup,
} from '../../datalab/constants';
import { OfferingsFilterFormType } from '../components/offerings-filter-form/OfferingsFilterForm.model';
import { dataGridColumns } from '../components/offerings-report-table/OfferingsReportTable.model';
import { Offering } from '../components/offerings-report-table/types';

export const alwaysVisibleColumns = ['issuer_name', 'issuer_primarySymbol'];

export const defaultVisibleColumns: ColDef<Offering, any>[] = [
  dataGridColumns.pricingDate,
  dataGridColumns.issuer_name,
  dataGridColumns.issuer_primarySymbol,
  dataGridColumns.issuer_sectorDisplayName,
  dataGridColumns.typeDisplayName,
  dataGridColumns.attributes_priceUsd,
  dataGridColumns.attributes_latestSizeInSecuritiesBase,
  dataGridColumns.attributes_latestGrossProceedsBaseUsd,
  dataGridColumns.attributes_latestGrossProceedsTotalUsd,
  dataGridColumns.attributes_marketCapAtPricingUsd,
  dataGridColumns.attributes_pctMarketCapAtPricing,
  dataGridColumns.attributes_latestPctSecondaryShares,
  dataGridColumns.attributes_pctFileToOffer,
  dataGridColumns.attributes_pctToLastTrade,
  dataGridColumns.attributes_pctOfferToOpen,
  dataGridColumns.attributes_pctOfferTo1Day,
  dataGridColumns.attributes_pctOfferTo7Day,
  dataGridColumns.attributes_pctOfferTo30Day,
  dataGridColumns.attributes_pctOfferTo180Day,
  dataGridColumns.attributes_pctOfferToCurrent,
  dataGridColumns.attributes_pctGrossSpread,
  dataGridColumns.attributes_initialIpoRangeUsd,
  dataGridColumns.attributes_pctVsMidpoint,
  dataGridColumns.attributes_pctChangeInSize,
  dataGridColumns.leftLead,
];

/*
  List of overriding props for INTL columns
*/
export const intlOverridingProps = {
  [dataGridColumns.attributes_latestGrossProceedsBaseUsd.field!]: {
    headerName: 'Gross Proceeds Base $',
  },
  [dataGridColumns.attributes_latestGrossProceedsTotalUsd.field!]: {
    headerName: 'Gross Proceeds Total $',
  },
  [dataGridColumns.attributes_marketCapAtPricingUsd.field!]: {
    headerName: 'Post-Offering Mkt. Cap $',
  },
  [dataGridColumns.attributes_priceUsd.field!]: {
    headerName: 'Offer Price $',
  },
  [dataGridColumns.attributes_lastTradeBeforeFilingUsd.field!]: {
    headerName: 'Last Trade Before Filing $',
  },
  [dataGridColumns.attributes_lastTradeBeforeOfferUsd.field!]: {
    headerName: 'Last Trade Before Offer $',
  },
  [dataGridColumns.attributes_initialIpoRangeUsd.field!]: {
    headerName: 'Initial IPO Range $',
  },
  [dataGridColumns.attributes_reOfferHighUsd.field!]: {
    headerName: 'Re Offer High $',
  },
  [dataGridColumns.attributes_reOfferLowUsd.field!]: {
    headerName: 'Re Offer Low $',
  },
  [dataGridColumns.attributes_initialGrossProceedsBaseUsd.field!]: {
    headerName: 'Gross Proceeds Base $ (Initial)',
  },
  [dataGridColumns.attributes_revisedGrossProceedsBaseUsd.field!]: {
    headerName: 'Gross Proceeds Base $ (Revised)',
  },
};

// master columns
export const orderedGlobalEcmColumns: ColDef<Offering, any>[] = [
  dataGridColumns.attributes_confidentialFilingDate,
  dataGridColumns.publicFilingDate,
  dataGridColumns.launchDate,
  dataGridColumns.marketTimingDisplayName, // timing_of_launch
  dataGridColumns.pricingDate,
  dataGridColumns.firstTradeDate,
  dataGridColumns.settlementDate,
  dataGridColumns.issuer_name,
  dataGridColumns.issuer_primarySymbol, // ticker
  dataGridColumns.issuer_cik,
  dataGridColumns.issuer_primaryCusip,
  dataGridColumns.issuer_primaryIsin,
  dataGridColumns.issuer_entityTypes,
  dataGridColumns.exchangeMicDisplayName,
  dataGridColumns.exchangeRegionDisplayName,
  dataGridColumns.exchangeCountryDisplayName,
  dataGridColumns.issuer_sectorDisplayName,
  dataGridColumns.issuer_subSectorDisplayName,
  dataGridColumns.typeDisplayName,
  dataGridColumns.securityTypeDisplayName,
  dataGridColumns.pricingCurrencyDisplayName,
  dataGridColumns.price, // filing_details_offering_price_local_curr
  dataGridColumns.attributes_priceUsd,
  dataGridColumns.attributes_netPrice,
  dataGridColumns.attributes_splitAdjustedOfferPrice,
  dataGridColumns.attributes_latestSizeInSecuritiesBase, // total shares offering_size
  dataGridColumns.attributes_initialSizeInSecuritiesBase, // total shares filed excl shoe
  dataGridColumns.attributes_latestSizeInSecuritiesBasePrimary, // primary shares base offering
  dataGridColumns.attributes_latestSizeInSecuritiesBaseSecondary, // secondary shares base offering
  dataGridColumns.attributes_latestPctSecondaryShares, // pct secondary shares
  dataGridColumns.attributes_latestOverAllotmentAuthorized, // ovlt auth
  dataGridColumns.attributes_overAllotmentExercised, // ovlt exercised
  dataGridColumns.attributes_latestGrossProceedsBase, // filing_details_gross_proceeds_local_curr
  dataGridColumns.attributes_latestGrossProceedsBaseUsd, // gross proceeds usd
  dataGridColumns.attributes_latestGrossProceedsTotal, // filing_details_gross_proceeds_with_over_allotment_local_curr
  dataGridColumns.attributes_latestGrossProceedsTotalUsd, // gross proceeds with ovlt usd
  dataGridColumns.attributes_initialGrossProceedsBaseUsd, // initial_gross_proceeds_base,
  dataGridColumns.attributes_initialGrossProceedsBase, // initial_gross_proceeds_base_local_curr,
  dataGridColumns.attributes_revisedGrossProceedsBaseUsd, // revised_gross_proceeds_base,
  dataGridColumns.attributes_revisedGrossProceedsBase, // revised_gross_proceeds_base_local_curr,
  dataGridColumns.attributes_marketCapAtPricing, // filing_details_market_cap_at_pricing_local_curr,
  dataGridColumns.attributes_marketCapAtPricingUsd, // filing_details_market_cap_at_pricing,
  dataGridColumns.attributes_marketCapPreOfferingUsd, // filing_details_market_cap_pre_offering,
  dataGridColumns.attributes_latestPostOfferingShares, // offering_post_offering_shares,
  dataGridColumns.attributes_preOfferingShares, // offering_pre_offering_shares,
  dataGridColumns.attributes_pctMarketCapAtPricing, // filing_details_deal_pct_at_pricing_market_cap,
  dataGridColumns.attributes_pctMarketCapPreOffering, // filing_details_deal_pct_market_cap_pre_offering,
  dataGridColumns.attributes_preOfferingAdtv, // offerings_pre_offering_adtv,
  dataGridColumns.attributes_sizeAsMultipleOfAdtv, // offerings_size_as_multiple_of_adtv,
  dataGridColumns.attributes_pctFileToOffer, // offerings_file_to_offer_discount,
  dataGridColumns.attributes_pctToLastTrade, // offerings_discount_to_last_trade,
  dataGridColumns.attributes_pctToVwap, // offering_discount_to_vwap,
  dataGridColumns.attributes_pctTo52WeekHigh, // offerings_discount_to_52wk_high,
  dataGridColumns.attributes_pctGrossSpread, // offering_gross_spread_pct,
  dataGridColumns.attributes_grossSpreadTotalUsd, // offering_gross_spread,
  dataGridColumns.attributes_allInCost, // offerings_all_in_cost,
  dataGridColumns.attributes_estimatedFeeUsd, // estimated_fee,
  dataGridColumns.attributes_initialIndicativeGrossProceedsUsd, // offering_initial_registration_value
  dataGridColumns.attributes_initialIpoRange,
  dataGridColumns.attributes_initialIpoRangeUsd,
  dataGridColumns.attributes_priceVsRangeDisplayName,
  dataGridColumns.attributes_pctVsMidpoint,
  dataGridColumns.attributes_pctChangeInSize,
  dataGridColumns.attributes_revisedIpoRangeUsd,
  dataGridColumns.attributes_lastTradeBeforeFiling,
  dataGridColumns.attributes_lastTradeBeforeFilingUsd,
  dataGridColumns.attributes_lastTradeBeforeOffer,
  dataGridColumns.attributes_lastTradeBeforeOfferUsd,
  dataGridColumns.attributes_fiftyTwoWeekHigh,
  dataGridColumns.attributes_reOfferLow,
  dataGridColumns.attributes_reOfferLowUsd,
  dataGridColumns.attributes_reOfferHigh,
  dataGridColumns.attributes_reOfferHighUsd,
  dataGridColumns.attributes_pctReOfferHigh,
  dataGridColumns.attributes_pctReOfferLow,
  dataGridColumns.attributes_firstDayVolume,
  dataGridColumns.attributes_firstDayTurnover,
  dataGridColumns.attributes_pctOfferToOpen,
  dataGridColumns.attributes_offerToVwap1Day,
  dataGridColumns.attributes_pctOfferTo1Day,
  dataGridColumns.attributes_pctOfferTo3Day,
  dataGridColumns.attributes_pctOfferTo7Day,
  dataGridColumns.attributes_pctOfferTo14Day,
  dataGridColumns.attributes_pctOfferTo30Day,
  dataGridColumns.attributes_pctOfferTo90Day,
  dataGridColumns.attributes_pctOfferTo180Day,
  dataGridColumns.attributes_pctOfferToOneYear,
  dataGridColumns.attributes_pctOfferToPriorQuarter,
  dataGridColumns.attributes_pctOfferToCurrent,
  dataGridColumns.attributes_totalManagers,
  dataGridColumns.attributes_totalBookrunners,
  dataGridColumns.attributes_totalNonBookrunners,
  dataGridColumns.attributes_totalPctToBookrunners,
  dataGridColumns.attributes_totalPctToNonBookrunners,
  dataGridColumns.attributes_totalPctToLeftLead,
  dataGridColumns.attributes_lockUpPeriod,
  dataGridColumns.attributes_lockUpExpirationDate,
  dataGridColumns.attributes_lockUpEarlyReleaseDate,
  dataGridColumns.attributes_isConditionalLockUp,
  dataGridColumns.attributes_hasMultipleLockUps,
  dataGridColumns.leftLead,
  dataGridColumns.attributes_primaryShareholderName,
  dataGridColumns.attributes_totalPreOfferingOwnership, // offering_pre_offering_ownership,
  dataGridColumns.attributes_totalPctPreOfferingOwnership, // offering_pct_pre_offering_ownership,
  dataGridColumns.attributes_totalPostOfferingOwnership, // offering_post_offering_ownership,
  dataGridColumns.attributes_totalPctPostOfferingOwnership, // offering_pct_post_offering_ownership,
  dataGridColumns.useOfProceedsDisplayNames,
  dataGridColumns.hasForwardAgreement,
  dataGridColumns.attributes_isFirstFollowOn,
  dataGridColumns.attributes_isCarveOut, // carve_out,
  dataGridColumns.attributes_isCleanUpTrade, // clean_up_trade,
  dataGridColumns.attributes_isConcurrentOffering, // concurrent_offering,
  dataGridColumns.attributes_isDualListed, // dual_listed,
  dataGridColumns.attributes_isEgc, // egc,
  dataGridColumns.attributes_isSyntheticSecondary, // synthetic_secondary,
  dataGridColumns.attributes_isUpListing, // up_listing,
  dataGridColumns.attributes_useOfProceedsNote, // use_of_proceeds_note,
  dataGridColumns.attributes_isCompanyRepurchase, // company_repurchase,
  dataGridColumns.issuer_headquarters, // headquarters,
  dataGridColumns.issuer_naics, // naics,
  dataGridColumns.attributes_hasCornerstoneInvestors, // cornerstone_investors,
  dataGridColumns.attributes_cornerstoneInvestorsTotalCount, // number_of_cornerstone_investors,
  dataGridColumns.cornerstoneInvestorList, // cornerstone_investors_list,
  dataGridColumns.attributes_cornerstoneTotalAmount, // cornerstone_investment,
  dataGridColumns.cornerstoneInvestmentTypes, // cornerstone_investment_type,
];

export const categorizedGlobalEcmColumns: CategorizedColumn = {
  [columnCategoryLabel[DatalabTableColumnCategory.ISSUER]]: [
    dataGridColumns.issuer_name,
    dataGridColumns.issuer_primarySymbol,
    dataGridColumns.issuer_cik,
    dataGridColumns.issuer_primaryCusip,
    dataGridColumns.issuer_primaryIsin,
    dataGridColumns.issuer_entityTypes,
    dataGridColumns.exchangeMicDisplayName,
    dataGridColumns.exchangeRegionDisplayName,
    dataGridColumns.pricingCurrencyDisplayName,
    dataGridColumns.exchangeCountryDisplayName,
    dataGridColumns.issuer_sectorDisplayName,
    dataGridColumns.issuer_subSectorDisplayName,
    dataGridColumns.attributes_isDualListed, // dual_listed,
    dataGridColumns.attributes_isEgc, // egc,
    dataGridColumns.issuer_headquarters, // headquarters,
    dataGridColumns.issuer_naics, // naics,
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.OFFERING_TERMS]]: [
    dataGridColumns.typeDisplayName,
    dataGridColumns.securityTypeDisplayName,
    dataGridColumns.price,
    dataGridColumns.attributes_priceUsd,
    dataGridColumns.attributes_netPrice,
    dataGridColumns.attributes_splitAdjustedOfferPrice,
    dataGridColumns.attributes_initialIndicativeGrossProceedsUsd, // initial registration value
    dataGridColumns.attributes_latestSizeInSecuritiesBase,
    dataGridColumns.attributes_initialSizeInSecuritiesBase,
    dataGridColumns.attributes_latestSizeInSecuritiesBasePrimary,
    dataGridColumns.attributes_latestSizeInSecuritiesBaseSecondary,
    dataGridColumns.attributes_latestPctSecondaryShares,
    dataGridColumns.attributes_latestOverAllotmentAuthorized, // ovlt auth
    dataGridColumns.attributes_overAllotmentExercised, // ovlt exercised
    dataGridColumns.attributes_latestGrossProceedsBase, // gross proceeds local curr
    dataGridColumns.attributes_latestGrossProceedsBaseUsd, // gross proceeds usd
    dataGridColumns.attributes_latestGrossProceedsTotal, // gross proceeds with ovlt local curr
    dataGridColumns.attributes_latestGrossProceedsTotalUsd, // gross proceeds with ovlt usd
    dataGridColumns.attributes_initialGrossProceedsBaseUsd, // initial_gross_proceeds_base,
    dataGridColumns.attributes_initialGrossProceedsBase, // initial_gross_proceeds_base_local_curr,
    dataGridColumns.attributes_revisedGrossProceedsBaseUsd, // revised_gross_proceeds_base,
    dataGridColumns.attributes_revisedGrossProceedsBase, // revised_gross_proceeds_base_local_curr,
    dataGridColumns.attributes_marketCapAtPricing, // filing_details_market_cap_at_pricing_local_curr,
    dataGridColumns.attributes_marketCapAtPricingUsd, // filing_details_market_cap_at_pricing,
    dataGridColumns.attributes_marketCapPreOfferingUsd, // filing_details_market_cap_pre_offering,
    dataGridColumns.attributes_latestPostOfferingShares, // offering_post_offering_shares,
    dataGridColumns.attributes_preOfferingShares, // offering_pre_offering_shares,
    dataGridColumns.attributes_pctMarketCapAtPricing, // filing_details_deal_pct_at_pricing_market_cap,
    dataGridColumns.attributes_pctMarketCapPreOffering, // filing_details_deal_pct_market_cap_pre_offering,
    dataGridColumns.attributes_preOfferingAdtv, // offerings_pre_offering_adtv,
    dataGridColumns.attributes_sizeAsMultipleOfAdtv, // offerings_size_as_multiple_of_adtv,
    dataGridColumns.attributes_initialIpoRange, // offering_initial_ipo_range_low_local_curr,
    dataGridColumns.attributes_initialIpoRangeUsd, // offering_initial_ipo_range_low,
    dataGridColumns.attributes_revisedIpoRangeUsd, // offering_revised_ipo_range_low,
    dataGridColumns.attributes_priceVsRangeDisplayName, // filing_details_price_vs_range,
    dataGridColumns.attributes_pctVsMidpoint, // offering_price_vs_midpoint,
    dataGridColumns.attributes_pctChangeInSize, // offering_pct_change_in_size,
    dataGridColumns.useOfProceedsDisplayNames, // offering_use_of_proceeds,
    dataGridColumns.hasForwardAgreement, // offering_forward_agreement,
    dataGridColumns.attributes_isFirstFollowOn, // first_follow_on,
    dataGridColumns.attributes_isConcurrentOffering, // concurrent_offering,
    dataGridColumns.attributes_isSyntheticSecondary, // synthetic_secondary,
    dataGridColumns.attributes_isUpListing, // up_listing,
    dataGridColumns.attributes_useOfProceedsNote, // use_of_proceeds_note,
    dataGridColumns.attributes_isCompanyRepurchase, // company_repurchase,
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.OWNERSHIP]]: [
    dataGridColumns.attributes_primaryShareholderName, // primary_shareholder_name,
    dataGridColumns.attributes_totalPreOfferingOwnership, // offering_pre_offering_ownership,
    dataGridColumns.attributes_totalPctPreOfferingOwnership, // offering_pct_pre_offering_ownership,
    dataGridColumns.attributes_totalPostOfferingOwnership, // offering_post_offering_ownership,
    dataGridColumns.attributes_totalPctPostOfferingOwnership, // offering_pct_post_offering_ownership,
    dataGridColumns.attributes_isCarveOut, // carve_out,
    dataGridColumns.attributes_isCleanUpTrade, // clean_up_trade,
    dataGridColumns.attributes_hasCornerstoneInvestors, // cornerstone_investors,
    dataGridColumns.cornerstoneInvestorList, // cornerstone_investors_list,
    dataGridColumns.attributes_cornerstoneTotalAmount, // cornerstone_investment,
    dataGridColumns.cornerstoneInvestmentTypes, // cornerstone_investment_type,
    dataGridColumns.attributes_cornerstoneInvestorsTotalCount, // number_of_cornerstone_investors,
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.PERFORMANCE]]: [
    dataGridColumns.attributes_firstDayVolume,
    dataGridColumns.attributes_firstDayTurnover,
    dataGridColumns.attributes_pctOfferToOpen,
    dataGridColumns.attributes_offerToVwap1Day,
    dataGridColumns.attributes_pctOfferTo1Day,
    dataGridColumns.attributes_pctOfferTo3Day,
    dataGridColumns.attributes_pctOfferTo7Day,
    dataGridColumns.attributes_pctOfferTo14Day,
    dataGridColumns.attributes_pctOfferTo30Day,
    dataGridColumns.attributes_pctOfferTo90Day,
    dataGridColumns.attributes_pctOfferTo180Day,
    dataGridColumns.attributes_pctOfferToOneYear,
    dataGridColumns.attributes_pctOfferToPriorQuarter,
    dataGridColumns.attributes_pctOfferToCurrent,
    dataGridColumns.attributes_fiftyTwoWeekHigh,
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.PRICING_DISCOUNT]]: [
    dataGridColumns.attributes_pctFileToOffer, // offerings_file_to_offer_discount,
    dataGridColumns.attributes_pctToLastTrade, // offerings_discount_to_last_trade,
    dataGridColumns.attributes_pctToVwap, // offering_discount_to_vwap,
    dataGridColumns.attributes_pctTo52WeekHigh, // offerings_discount_to_52wk_high,
    dataGridColumns.attributes_allInCost, // offerings_all_in_cost,
    dataGridColumns.attributes_lastTradeBeforeFiling, // last_trade_before_filing_local_curr,
    dataGridColumns.attributes_lastTradeBeforeFilingUsd, // last_trade_before_filing,
    dataGridColumns.attributes_lastTradeBeforeOffer, // last_trade_before_offer_local_curr,
    dataGridColumns.attributes_lastTradeBeforeOfferUsd, // last_trade_before_offer,
    dataGridColumns.attributes_reOfferLow, // re_offer_low_local_curr,
    dataGridColumns.attributes_reOfferLowUsd, // re_offer_low,
    dataGridColumns.attributes_pctReOfferLow, // re_offer_discount_low,
    dataGridColumns.attributes_reOfferHigh, // re_offer_high_local_curr,
    dataGridColumns.attributes_reOfferHighUsd, // re_offer_high,
    dataGridColumns.attributes_pctReOfferHigh, // re_offer_discount_high,
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.TIMING]]: [
    dataGridColumns.attributes_confidentialFilingDate,
    dataGridColumns.publicFilingDate,
    dataGridColumns.launchDate,
    dataGridColumns.marketTimingDisplayName, // timing of launch
    dataGridColumns.pricingDate,
    dataGridColumns.firstTradeDate,
    dataGridColumns.settlementDate,
    dataGridColumns.attributes_lockUpPeriod, // lockup period
    dataGridColumns.attributes_lockUpEarlyReleaseDate, // early release date
    dataGridColumns.attributes_lockUpExpirationDate, // lockup exp
    dataGridColumns.attributes_isConditionalLockUp, // conditional lockup
    dataGridColumns.attributes_hasMultipleLockUps, // multiple lockup
  ],
  [columnCategoryLabel[DatalabTableColumnCategory.UNDERWRITING]]: [
    dataGridColumns.attributes_pctGrossSpread, // offering_gross_spread_pct,
    dataGridColumns.attributes_grossSpreadTotalUsd, // offering_gross_spread,
    dataGridColumns.attributes_estimatedFeeUsd, // estimated_fee,
    dataGridColumns.attributes_totalManagers, // offering_total_managers,
    dataGridColumns.attributes_totalBookrunners, // offering_total_bookrunners,
    dataGridColumns.attributes_totalNonBookrunners, // offering_total_non_bookrunners,
    dataGridColumns.attributes_totalPctToBookrunners, // offering_total_pct_to_bookrunners,
    dataGridColumns.attributes_totalPctToNonBookrunners, // offering_total_pct_to_non_bookrunners,
    dataGridColumns.attributes_totalPctToLeftLead, // offering_total_pct_to_left_lead,
    dataGridColumns.leftLead, // left_lead_firm_name,
  ],
};

export const localCurrVisibleColumns = [
  dataGridColumns.exchangeMicDisplayName,
  dataGridColumns.exchangeRegionDisplayName,
  dataGridColumns.exchangeCountryDisplayName,
  dataGridColumns.pricingCurrencyDisplayName,
  dataGridColumns.price,
  dataGridColumns.attributes_latestGrossProceedsBase,
  dataGridColumns.attributes_latestGrossProceedsTotal,
  dataGridColumns.attributes_marketCapAtPricing,
  dataGridColumns.attributes_initialIpoRange,
  dataGridColumns.attributes_lastTradeBeforeFiling,
  dataGridColumns.attributes_lastTradeBeforeOffer,
  dataGridColumns.attributes_reOfferLow,
  dataGridColumns.attributes_reOfferHigh,
  dataGridColumns.attributes_initialGrossProceedsBase,
  dataGridColumns.attributes_revisedGrossProceedsBase,
];

export const groupedColumns: CategorizedColumn = {
  [columnGroupLabel[DatalabTableColumnGroup.LOCAL_CURRENCY]]: [
    dataGridColumns.price,
    dataGridColumns.attributes_latestGrossProceedsBase,
    dataGridColumns.attributes_latestGrossProceedsTotal,
    dataGridColumns.attributes_marketCapAtPricing,
    dataGridColumns.attributes_initialIpoRange,
    dataGridColumns.attributes_lastTradeBeforeFiling,
    dataGridColumns.attributes_lastTradeBeforeOffer,
    dataGridColumns.attributes_reOfferLow,
    dataGridColumns.attributes_reOfferHigh,
    dataGridColumns.attributes_initialGrossProceedsBase,
    dataGridColumns.attributes_revisedGrossProceedsBase,
  ],
  [columnGroupLabel[DatalabTableColumnGroup.USD]]: [
    dataGridColumns.attributes_priceUsd,
    dataGridColumns.attributes_latestGrossProceedsBaseUsd,
    dataGridColumns.attributes_latestGrossProceedsTotalUsd,
    dataGridColumns.attributes_marketCapAtPricingUsd,
    dataGridColumns.attributes_initialIpoRangeUsd,
    dataGridColumns.attributes_lastTradeBeforeFilingUsd,
    dataGridColumns.attributes_lastTradeBeforeOfferUsd,
    dataGridColumns.attributes_reOfferLowUsd,
    dataGridColumns.attributes_reOfferHighUsd,
    dataGridColumns.attributes_initialGrossProceedsBaseUsd,
    dataGridColumns.attributes_revisedGrossProceedsBaseUsd,
  ],
};

export const defaultSortModel: NestedSortInput = {
  pricingDate: SortEnumType.Desc,
};

export const downloadOptions = [
  {
    value: 'includeUnderwritersWorksheet',
    label: 'Underwriters',
  },
];

const ONE_MILLION = 1000000;
export const mapFormTypeToGlobalEcmFilterInput = (
  fields: OfferingsFilterFormType
): OfferingFilterInput => {
  const {
    date,
    marketCap,
    grossProceedsBase,
    sellingShareholderPct,
    leftleads,
    sectors: sectorsField,
    customSectors: customSectorsField,
    countries,
    underwriters,
    shareholders,
    advisers,
    include144A,
  } = fields;
  const marketCapAtPricingUsd =
    marketCap?.min || marketCap?.max
      ? {
          gte: marketCap?.min ? marketCap?.min * ONE_MILLION : undefined,
          lte: marketCap?.max ? marketCap?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const latestGrossProceedsBaseUsd =
    grossProceedsBase?.min || grossProceedsBase?.max
      ? {
          gte: grossProceedsBase?.min ? grossProceedsBase?.min * ONE_MILLION : undefined,
          lte: grossProceedsBase?.max ? grossProceedsBase?.max * ONE_MILLION : undefined,
        }
      : undefined;
  const latestPctSecondaryShares =
    sellingShareholderPct?.min || sellingShareholderPct?.max
      ? {
          gte: sellingShareholderPct?.min ? sellingShareholderPct?.min : undefined,
          lte: sellingShareholderPct?.max ? sellingShareholderPct?.max : undefined,
        }
      : undefined;
  const leftLeadId = (leftleads ?? []).length > 0 ? { in: leftleads } : undefined;
  const exchangeCountry = (countries ?? []).length > 0 ? { in: countries } : undefined;

  const sectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SECTOR'))
    .map(s => s.split(':')[1] as Sector);
  const sector = sectors.length > 0 ? { sector: { in: sectors } } : undefined;
  const subSectors = ((sectorsField as string[]) ?? [])
    .filter(s => s.startsWith('SUB_SECTOR'))
    .map(s => s.split(':')[1] as SubSector);
  const subSector = subSectors.length > 0 ? { subSector: { in: subSectors } } : undefined;
  const sectorOrSubSector =
    sector || subSector
      ? { or: [sector, subSector].filter(s => !!s) as IssuerFilterInput[] }
      : undefined;

  const customSectors =
    (customSectorsField ?? []).length > 0
      ? {
          or: Object.entries(
            customSectorsField!.reduce((result, value) => {
              const [type, id] = value.split(':');
              const key = type === 'SUB_SECTOR' ? 'customSubsectorId' : 'customSectorId';
              result[key] = result[key] ?? { in: [] };
              result[key]!.in!.push(id); // `!` required because of how CustomSectorIssuerFilterInput is typed
              return result;
            }, {} as CustomSectorIssuerFilterInput)
          ).map(([key, filter]) => ({ [key]: filter })),
        }
      : undefined;

  return {
    status: { eq: OfferingStatus.Priced },
    pricingDate:
      date?.start || date?.end
        ? {
            gte: date?.start ? date?.start : undefined,
            lte: date?.end ? date?.end : undefined,
          }
        : undefined,
    attributes: {
      ...(marketCapAtPricingUsd ||
      latestGrossProceedsBaseUsd ||
      latestPctSecondaryShares ||
      leftLeadId ||
      exchangeCountry
        ? {
            marketCapAtPricingUsd,
            latestGrossProceedsBaseUsd,
            latestPctSecondaryShares,
            leftLeadId,
            exchangeCountry,
          }
        : undefined),
      isSupersededBy: { eq: null },
    },
    issuer:
      sectorOrSubSector || customSectors
        ? {
            ...sectorOrSubSector,
            customSectors,
          }
        : undefined,
    managers:
      (underwriters ?? []).length > 0
        ? { some: { manager: { id: { in: underwriters } } } }
        : undefined,
    shareholders:
      (shareholders ?? []).length > 0
        ? { some: { shareholderId: { in: shareholders } } }
        : undefined,
    advisers: (advisers ?? []).length > 0 ? { some: { adviserId: { in: advisers } } } : undefined,
    isRule144A: !include144A ? { eq: false } : undefined,
  };
};
